import _dropdownMenu from "../_dropdownMenu";
export var ProjectTagsComponent = {
    props: {
        selectedAssets: Array,
        commonTags: Array,
        availableTags: Array,
    },
    data: function () {
        return {
            isAddMode: false,
            inputInitialized: false,
        };
    },
    methods: {
        removeTag: function (tag) {
            this.selectedAssets.forEach(function (asset) {
                asset.tags = asset.tags.filter(function (el) { return el.idTag !== tag.idTag; });
                $.post(BASE_URL + 'pictureValidator/removeTag', {
                    idAsset: asset.idAsset,
                    idTag: tag.idTag
                });
            });
        },
        addTag: function (tagToAdd) {
            this.selectedAssets.forEach(function (asset) {
                if (asset.tags.some(function (tag) { return tagToAdd.idTag === tag.idTag; }))
                    return;
                asset.tags.push(tagToAdd);
                $.post(BASE_URL + 'pictureValidator/addTag', {
                    idAsset: asset.idAsset,
                    idTag: tagToAdd.idTag
                });
            });
        },
        enterAddMode: function () {
            var _this = this;
            this.isAddMode = true;
            setTimeout(function () {
                var input = _this.$refs.input;
                input.focus();
                if (_this.inputInitialized)
                    return;
                _dropdownMenu({
                    entrySearchInput: input,
                    availableEntries: _this.availableTags,
                    nameOfIdField: 'idTag',
                    nameOfValueField: 'name',
                    placeResultElAfter: null
                });
                input.addEventListener('entrySelected', function (e) {
                    var tag = e.detail.selectedEntry;
                    _this.addTag(tag);
                    input.value = '';
                    setTimeout(function () {
                        _this.isAddMode = false;
                    }, 10);
                });
                _this.inputInitialized = true;
            }, 10);
        }
    },
    template: "\n    <div class='tag-container'>\n        <div class='tags'>\n            <span\n                v-for='tag in commonTags'\n                class='tag'>{{tag.name}}\n            <i class=\"fas fa-times delete-tag-button\"\n                @click='removeTag(tag)'></i></span>\n        </div>\n        <div\n            class='add-tag-container'\n            @click='enterAddMode'>\n            <button\n                class='add-tag-button'\n                v-if='!isAddMode'>+</button>\n            <input\n                v-show='isAddMode'\n                @blur='isAddMode = !isAddMode'\n                ref='input'>\n        </div>\n    </div>\n    ",
    created: function () {
    }
};
