require("./tags");
require("./tasks");
import { TagsComponent } from "./tags";
import { TasksComponent } from "./tasks";
document.addEventListener('DOMContentLoaded', function () {
    if (!document.querySelector('[data-page="template/template"]'))
        return;
    var app = new Vue({
        el: '.app',
        data: {
            currentTab: 'Planner',
            tabs: ['Planner', 'Livraison']
        },
        components: {
            'tags-component': TagsComponent,
            'tasks-component': TasksComponent,
        },
        computed: {
            currentTabComponent: function () {
                if (this.currentTab === 'Planner')
                    return "tags-component";
                return "tasks-component";
            }
        }
    });
});
