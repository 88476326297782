var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import HtmlHelper from '../helper';
import { NoteModal } from '../timetracker/_noteModal';
import Dropdown from '../_dropdownMenu';
import ProjectTimeEntry from './projectTimeEntry';
var TeamWrapper = /** @class */ (function () {
    function TeamWrapper(teamWrapper, projects, workers, setActiveTeamCallback, incrementValidationCount, decrementValidationCount) {
        this.isValidated = false;
        this.teamWrapperEl = teamWrapper;
        this.addProjectEls = Array.from(this.teamWrapperEl.querySelectorAll('.project-add'));
        this.setActiveTeamCallback = setActiveTeamCallback;
        this.incrementValidationCount = incrementValidationCount;
        this.decrementValidationCount = decrementValidationCount;
        this.idCE = parseInt(this.teamWrapperEl.querySelector('.ce-name').getAttribute('data-id-user'));
        this.idPresetTeam = parseInt(this.teamWrapperEl.getAttribute('data-id-preset-team'));
        this.date = this.teamWrapperEl.dataset.date;
        this.isValidated = this.teamWrapperEl.querySelector('.overflow-container.collapsed') !== null;
        this.projects = projects;
        this.workers = workers;
        this.setupListener();
    }
    TeamWrapper.getTeamWrappers = function (projects, workers, setActiveTeamCallback, incrementValidationCount, decrementValidationCount) {
        var teams = Array.from(document.querySelectorAll('.team-wrapper'));
        return teams.map(function (teamEl) { return new TeamWrapper(teamEl, projects, workers, setActiveTeamCallback, incrementValidationCount, decrementValidationCount); });
    };
    TeamWrapper.prototype.setupListener = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.teamWrapperEl.addEventListener('click', function () {
                    _this.setActiveTeamCallback(_this);
                }, true);
                this.setupAddUserListener();
                this.setupWorkerEntriesListeners();
                this.setupValidateListener();
                this.setupUndoValidateListener();
                this.addProjectEls.forEach(function (el) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.setupAddProjectListener(el);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    TeamWrapper.prototype.setupAddProjectListener = function (el) {
        var _this = this;
        var inputGroup = el.nextElementSibling;
        el.addEventListener('click', function () {
            el.classList.add('hide');
            inputGroup.classList.remove('hidden');
        });
        var projectInput = inputGroup.firstElementChild;
        var hourInput = projectInput.nextElementSibling;
        var saveEl = inputGroup.querySelector('.save');
        var abortEl = inputGroup.querySelector('.abort');
        var idProject;
        var idUser = parseInt(el.parentElement.parentElement.previousElementSibling.getAttribute('data-id-user'));
        var hours;
        var dd = Dropdown({
            entrySearchInput: projectInput,
            availableEntries: this.projects,
            nameOfIdField: "idProject",
            nameOfValueField: 'value',
            placeResultElAfter: ''
        });
        projectInput.addEventListener('entrySelected', function (e) {
            projectInput.nextElementSibling.nextElementSibling.classList.remove('hide');
            idProject = parseFloat(e.detail.selectedEntry.idProject);
        });
        hourInput.addEventListener('change', function () {
            hours = parseFloat(hourInput.value);
            saveEl.classList.remove('hide');
        });
        hourInput.addEventListener('keyup', function () {
            hours = parseFloat(hourInput.value);
            saveEl.classList.remove('hide');
        });
        saveEl.addEventListener('click', function (e) {
            var projectTimeEntry = new ProjectTimeEntry(_this.date, idUser, _this.idCE, idProject, hours);
            _this.addProjectEntryToUser(projectTimeEntry);
        });
        abortEl.addEventListener('click', function () {
            el.classList.remove('hide');
            inputGroup.classList.add('hidden');
            projectInput.value = '';
            hourInput.classList.add('hide');
            hourInput.value = '0';
            saveEl.classList.add('hide');
        });
    };
    TeamWrapper.prototype.setupValidateListener = function () {
        var _this = this;
        var validateButton = this.teamWrapperEl.querySelector('.validate-button');
        validateButton.addEventListener('click', function () { _this.validateTeam(); });
    };
    TeamWrapper.prototype.setupUndoValidateListener = function () {
        var _this = this;
        var resetButton = this.teamWrapperEl.querySelector('.reset-button');
        resetButton.addEventListener('click', function () { _this.undoValidateTeam(); });
    };
    TeamWrapper.prototype.validateTeam = function () {
        // collapse entry
        this.teamWrapperEl.querySelector('.overflow-container').classList.add('collapsed');
        // show reset button
        this.teamWrapperEl.querySelector('.reset-button').classList.remove('hide');
        // hide validate button
        this.teamWrapperEl.querySelector('.validate-button').classList.add('hide');
        this.incrementValidationCount();
        this.createValidatedEntries();
        this.isValidated = true;
    };
    TeamWrapper.prototype.createValidatedEntries = function () {
        var _this = this;
        var projectTimeEntries = [];
        // get each user row
        var rows = Array.from(this.teamWrapperEl.querySelectorAll('.user-row'));
        rows.forEach(function (row) {
            var idUser = parseInt(row.querySelector('.user-label').getAttribute('data-id-user'));
            // get all entries
            var entries = Array.from(row.querySelectorAll('.project-entry'));
            entries.forEach(function (entry) {
                var idProject = parseFloat(entry.querySelector('.project').getAttribute('data-id-project'));
                var hours = parseFloat(entry.querySelector('.worker-hours').value);
                projectTimeEntries.push({
                    date: _this.date,
                    idUser: idUser,
                    idCE: _this.idCE,
                    idProject: idProject,
                    hours: hours,
                    validatedByAdmin: 1
                });
            });
        });
        $.post(BASE_URL + "timetracker/validateTeamEntries", { projectTimeEntries: projectTimeEntries });
    };
    TeamWrapper.prototype.undoValidateTeam = function () {
        // expand entry
        this.teamWrapperEl.querySelector('.overflow-container').classList.remove('collapsed');
        // hide reset button
        this.teamWrapperEl.querySelector('.reset-button').classList.add('hide');
        // show validate button
        this.teamWrapperEl.querySelector('.validate-button').classList.remove('hide');
        this.decrementValidationCount();
        $.post(BASE_URL + "timetracker/undoTeamValidation", { idCE: this.idCE, date: this.date });
    };
    TeamWrapper.prototype.setupWorkerEntriesListeners = function () {
        var _this = this;
        var idPresetTeam = this.teamWrapperEl.dataset.idPresetTeam;
        var workerEntryEls = this.teamWrapperEl.querySelectorAll('.worker-entry');
        var notes = Array.from(this.teamWrapperEl.querySelectorAll('.note'));
        Array.from(this.teamWrapperEl.querySelectorAll('.worker-hours')).forEach(function (el) { return el.addEventListener('change', function () {
            _this.setActiveTeamCallback(_this);
            _this.updateTotalForActiveTeamEntry();
            ProjectTimeEntry.submitProjectTimeEntryByEl(el, _this.isValidated);
        }); });
        notes.forEach(function (note) {
            note.addEventListener('click', function () { _this.showNoteModal(note); });
        });
    };
    TeamWrapper.prototype.setupAddUserListener = function () {
        var _this = this;
        var userEntries = this.teamWrapperEl.querySelector('.entries');
        var addUserButton = this.teamWrapperEl.querySelector('.add-user');
        var addUserContainer = addUserButton.parentNode;
        var userRow = document.querySelector('.user-row.template');
        var addUserRow = this.teamWrapperEl.querySelector('.add-user-row');
        var input = addUserRow.querySelector('input');
        var actions = addUserRow.querySelector('.actions');
        var save = actions.querySelector('.save');
        var abort = actions.querySelector('.abort');
        var summary = document.querySelector('.worker-total');
        var dd = Dropdown({
            entrySearchInput: input,
            availableEntries: this.workers,
            nameOfIdField: 'idUser',
            nameOfValueField: 'dtHandle',
            placeResultElAfter: input
        });
        addUserButton.addEventListener('click', function () {
            addUserContainer.classList.add('hide');
            input.classList.remove('hide');
            actions.classList.remove('hide');
        });
        var selectedUser;
        input.addEventListener('entrySelected', function (e) {
            selectedUser = e.detail.selectedEntry;
            // show the save button
            save.classList.remove('hide');
        });
        save.addEventListener('click', function () {
            var rowTemplate = userRow.cloneNode(true);
            var summaryTemplate = summary.cloneNode(true);
            var idUser = selectedUser.idUser;
            var handle = selectedUser.dtHandle;
            var userLabel = rowTemplate.querySelector('.user-label');
            rowTemplate.classList.remove('template');
            summaryTemplate.classList.remove('template');
            userLabel.setAttribute('data-id-user', idUser);
            userLabel.textContent = handle;
            userEntries.appendChild(rowTemplate);
            summaryTemplate.textContent = '0';
            _this.teamWrapperEl.querySelector('.worker-summaries').appendChild(summaryTemplate);
            // restore add user button and hide input stuff
            addUserContainer.classList.remove('hide');
            save.classList.add('hide');
            input.classList.add('hide');
            actions.classList.add('hide');
            input.value = '';
            // bind listener to add project button
            _this.setupAddProjectListener(rowTemplate.querySelector('.project-add'));
        });
        abort.addEventListener('click', function () {
            addUserContainer.classList.remove('hide');
            actions.classList.add('hide');
            input.classList.add('hide');
            input.value = '';
            save.classList.add('hide');
        });
    };
    TeamWrapper.prototype.addProjectEntryToUser = function (entry) {
        var _this = this;
        var date = entry.date, idCE = entry.idCE, idUser = entry.idUser, idProject = entry.idProject, hours = entry.hours;
        var userRow = this.teamWrapperEl.querySelector(".user-row [data-id-user='" + idUser + "']").parentElement;
        var projectContainer = userRow.querySelector('.project-container');
        var addProjectContainer = projectContainer.querySelector('.add-project-container');
        var inputs = addProjectContainer.querySelectorAll('input');
        var element = HtmlHelper.createElementFromHTML("\n\t\t\t<div class='project-entry'>\n\t\t\t\t<span class='project' data-id-project=" + idProject + ">" + idProject + "</span>\n\t\t\t\t<input type='number' step='.5' min='0' class=\"worker-hours\" value='" + hours + "'>\n\t\t\t</div>\n\t\t");
        var note = document.createElement('i');
        note.className = 'far fa-clipboard note';
        // add note click listener to open modal
        note.addEventListener('click', function () { _this.showNoteModal(note); });
        var spacerEl = document.createElement('div');
        spacerEl.classList.add('spacer');
        projectContainer.insertBefore(element, addProjectContainer);
        element.parentNode.insertBefore(spacerEl, element.nextSibling);
        spacerEl.parentNode.insertBefore(note, spacerEl);
        addProjectContainer.querySelector('.project-add').classList.remove('hide');
        addProjectContainer.querySelector('.input-group').classList.add('hidden');
        // clear inputs
        Array.from(inputs).forEach(function (input) { input.value = ''; });
        // hide workhour input
        inputs[1].classList.add('hide');
        // add change listener to hour  input field to update totals
        element.querySelector('input').addEventListener('change', function () {
            _this.updateTotalForActiveTeamEntry();
        });
        // hide save button
        addProjectContainer.querySelector('.save').classList.add('hide');
        this.updateTotalForActiveTeamEntry();
        // create entry in backend
        $.post(BASE_URL + "timetracker/addProjectTimeEntry", {
            date: date,
            idUser: idUser,
            idCE: idCE,
            idProject: idProject,
            hours: hours
        });
    };
    TeamWrapper.prototype.showNoteModal = function (note) {
        return __awaiter(this, void 0, void 0, function () {
            var teamWrapper, date, idCE, idUser, idProject, workers;
            return __generator(this, function (_a) {
                teamWrapper = note.closest('.team-wrapper');
                date = teamWrapper.getAttribute('data-date');
                idCE = parseInt(teamWrapper.getAttribute('data-id-ce'));
                idUser = parseInt(note.parentElement.previousElementSibling.getAttribute('data-id-user'));
                idProject = parseFloat(note.previousElementSibling.querySelector('.project').getAttribute('data-id-project'));
                workers = this.workers;
                document.querySelector('.notes-modal').classList.remove('hide');
                new NoteModal(note, new ProjectTimeEntry(date, idUser, idCE, idProject, null, null), workers);
                return [2 /*return*/];
            });
        });
    };
    TeamWrapper.prototype.updateTotalForActiveTeamEntry = function () {
        var _this = this;
        // update total time for each worker
        var userRows = this.teamWrapperEl.querySelectorAll('.user-row');
        userRows.forEach(function (row, index) {
            var idUser = row.querySelector('.user-label').getAttribute('data-id-user');
            var totalHours = 0;
            // sum up worked hours value per row
            Array.from(row.querySelectorAll('.worker-hours')).forEach(function (entry) { totalHours += parseFloat(entry.value); });
            _this.teamWrapperEl.querySelectorAll('.worker-total')[index].textContent = "" + totalHours;
        });
        // update project total
        var idProjects = Array.from(this.teamWrapperEl.querySelectorAll('[data-id-project]'))
            .map(function (el) { return parseFloat(el.getAttribute('data-id-project')); });
        // remove duplicates
        idProjects = Array.from(new Set(idProjects));
        var projectEntries = idProjects.map(function (idProject) {
            return {
                idProject: idProject,
                total: Array.from(_this.teamWrapperEl.querySelectorAll(".user-row [data-id-project='" + idProject + "']")).map(function (el) { return parseFloat(el.nextElementSibling.value); })
                    .reduce(function (carry, hours) { return carry + hours; })
            };
        });
        var totalEntries = Array.from(this.teamWrapperEl.querySelectorAll(".project-summary .project-entry"));
        // apply total to view
        projectEntries.forEach(function (entry) {
            // find entry in total
            var entryInTotal = totalEntries.find(function (totalEntry) {
                return totalEntry.querySelector('.project').textContent === "" + entry.idProject;
            });
            // entry found
            if (entryInTotal) {
                entryInTotal.querySelector('.worker-hours').textContent = entry.total + "";
            }
            // create new entry
            else {
                var newEntry = HtmlHelper.createElementFromHTML("\n\t\t\t\t\t<div class='project-entry'>\n\t\t\t\t\t\t<span class='project'>" + entry.idProject + "</span>\n\t\t\t\t\t\t<span class='worker-hours'>" + entry.total + "</span>\n\t\t\t\t\t</div>\n\t\t\t\t");
                var spacer = document.createElement('div');
                spacer.classList.add('spacer');
                _this.teamWrapperEl.querySelector('.team-total').parentNode.insertBefore(newEntry, _this.teamWrapperEl.querySelector('.team-total'));
                newEntry.parentNode.insertBefore(spacer, newEntry.nextSibling);
            }
        });
        this.teamWrapperEl.querySelector('.team-total').textContent = projectEntries.reduce(function (carry, el) {
            return carry + el.total;
        }, 0) + "";
    };
    return TeamWrapper;
}());
export default TeamWrapper;
