import VanillaToasts from 'vanillatoasts';
import Moment from 'moment';
require("floatthead")
$(() => {
	if ($("[data-page='users']").length) {
		require('@chenfengyuan/datepicker');

		// ******** VARIABLES ***********
		let $modal = $(".modal");
		let $blockDateInput = $(".block-date-input");

		let selectedIdUser;
		let previousState = [];

		let userArray = [];

		// ******** SETUP ***************
		setupDatepicker();
		$("table").floatThead();
		

		$('.user_header_actions').on('change', function () {
			$.ajax({
				type: 'POST',
				url: BASE_URL + 'user/updateUserGroup',
				data: {
					fiGroup: $(this).val(),
					users: userArray
				},
				dataType: 'json',
				success: (result) => {
					if (result.success) {
						VanillaToasts.create({
							text: result.message,
							type: 'success',
							timeout: 3000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});
					} else {
						VanillaToasts.create({
							text: result.message,
							type: 'error',
							timeout: 3000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});
					}
				},
				error: (error) => {
					console.log(error);
				},
			});
		});

		$('input[name="multi-group"]').on('click', function () {
			let userId = $(this).val();
			userArray.push(userId);
		});

		// ******** FUNCTIONS ***********

		function setSelectedIdUser() {
			selectedIdUser = $(this).data("id-user");

			// check if selected user is already inside the previous state array
			let inPrevStateArray = previousState.some((el) => {
				return el.idUser === selectedIdUser
			});

			if (!inPrevStateArray) {
				let $userRow = getSelectedUserRow();

				previousState.push({
					idUser: selectedIdUser,
					firstName: $userRow.find('input').eq(1).val(),
					lastName: $userRow.find('input').eq(2).val(),
					nickName: $userRow.find('input').eq(3).val(),
					handle: $userRow.find('input').eq(0).val(),
					emailAddress: $userRow.find('input').eq(4).val(),
					phoneNumber: $userRow.find('input').eq(5).val(),
					fiGroup: $userRow.find('select').val(),
				});
			}
		}

		window.debugInfo = () => {
			console.table(previousState);
			console.dir(selectedIdUser);

		}

		function setupDatepicker() {
			let datepickerOptions = {
				startDate: Date(),
				format: "dd.mm.yyyy",
				autoHide: true
			}
			$blockDateInput.datepicker(datepickerOptions);
			$blockDateInput.on("pick.datepicker", () => {
				$(".modal .hint").fadeOut();
			});
		}

		function getSelectedUserRow() {
			return $(`[data-id-user=${selectedIdUser}]`);
		}

		function showOptions() {
			getSelectedUserRow().find(".update-options").fadeIn().css("display", "flex");
		}

		function hideOptionsForRow($userRow) {
			$userRow.find(".update-options").fadeOut();
		}

		async function updateUser() {
			let $userRow = $(this).closest("tr");

			
			let user = {
				idUser: selectedIdUser,
				handle: $userRow.find('input:not([type=checkbox])').eq(0).val(),
				firstName: $userRow.find('input:not([type=checkbox])').eq(1).val(),
				lastName: $userRow.find('input:not([type=checkbox])').eq(2).val(),
				nickName: $userRow.find('input:not([type=checkbox])').eq(3).val(),
				emailAddress: $userRow.find('input:not([type=checkbox])').eq(4).val() || null,
				phoneNumber: $userRow.find('input:not([type=checkbox])').eq(5).val() || null,
				fiGroup: $userRow.find('select').val(),
				markerColor: $userRow.find('input:not([type=checkbox])').eq(6).val() || null,
				password: $userRow.find('input:not([type=checkbox])').eq(7).val() || null,
			}

			user.firstName = user.firstName === '' ? null : user.firstName;
			user.lastName = user.lastName === '' ? null : user.lastName;
			user.nickName = user.nickName === '' ? null : user.nickName;
			user.emailAddress = user.emailAddress === '' ? null : user.emailAddress;
			user.phoneNumber = user.phoneNumber === '' ? null : user.phoneNumber;
			user.markerColor = user.markerColor === '' ? null : user.markerColor;
			user.password = user.password === '' ? null : user.password;
			

			await $.post(`${BASE_URL}user/updateUser`, user);
			hideOptionsForRow($userRow);
			VanillaToasts.create({
				text: 'Successfully updated the user',
				timeout: 5000
			});
		}

		async function addUser() {
			let $userRow = $(this).closest("tr");
			
			let user = {
				handle: $userRow.find('input:not([type=checkbox])').eq(0).val(),
				firstName: $userRow.find('input:not([type=checkbox])').eq(1).val(),
				lastName: $userRow.find('input:not([type=checkbox])').eq(2).val(),
				nickName: $userRow.find('input:not([type=checkbox])').eq(3).val(),
				emailAddress: $userRow.find('input:not([type=checkbox])').eq(4).val(),
				phoneNumber: $userRow.find('input:not([type=checkbox])').eq(5).val(),
				fiGroup: $userRow.find('select').val(),
				markerColor: $userRow.find('input:not([type=checkbox])').eq(6).val(),
				password: $userRow.find('input:not([type=checkbox])').eq(7).val(),
			}
			
			user.firstName = user.firstName === '' ? null : user.firstName;
			user.lastName = user.lastName === '' ? null : user.lastName;
			user.nickName = user.nickName === '' ? null : user.nickName;
			user.emailAddress = user.emailAddress === '' ? null : user.emailAddress;
			user.phoneNumber = user.phoneNumber === '' ? null : user.phoneNumber;
			user.markerColor = user.markerColor === '' ? null : user.markerColor;
			user.password = user.password === '' ? null : user.password;
			
			let result = JSON.parse(await $.post(`${BASE_URL}user/addUser`, user));
			if (result.success) {
				// create new row
				let $template = $userRow.next().clone();

				$template.attr("data-id-user", result.idUser);
				$template.find('input').eq(1).val(user.handle);
				$template.find('input').eq(2).val(user.firstName);
				$template.find('input').eq(3).val(user.lastName);
				$template.find('input').eq(4).val(user.nickName);
				$template.find('input').eq(5).val(user.emailAddress);
				$template.find('input').eq(6).val(user.phoneNumber);
				$template.find('input').eq(7).val(user.markerColor);
				$template.find('select').val(user.fiGroup);

				$userRow.after($template);

				// reset add row fields
				$userRow.find("input").val("");
				$userRow.find("select").val(4);
				selectedIdUser = result.idUser;
			}
			VanillaToasts.create({
				text: result.message,
				timeout: 5000
			});
		}

		async function deleteUser() {
			if (confirm("Voulez vous vraiment supprimer cet utilisateur?")) {
				let result = JSON.parse(await $.post(`${BASE_URL}user/deleteUser`, { idUser: selectedIdUser }));

				if (result.success) {
					getSelectedUserRow().fadeOut();
					VanillaToasts.create({
						text: result.message,
						timeout: 5000
					});
				}
			}
		}

		function undoChanges() {
			let $userRow = $(this).closest("tr");
			let idUser = $userRow.data("id-user");
			let userObj = previousState.find(el => el.idUser === idUser);

			$userRow.find('input').eq(1).val(userObj.handle);
			$userRow.find('input').eq(2).val(userObj.firstName);
			$userRow.find('input').eq(3).val(userObj.lastName);
			$userRow.find('input').eq(4).val(userObj.nickName);
			$userRow.find('input').eq(5).val(userObj.emailAddress);
			$userRow.find('input').eq(6).val(userObj.phonenumber);
			$userRow.find('select').val(userObj.fiGroup);

			hideOptionsForRow($userRow);
		}

		async function updateUserExpiryDate() {
			let expirationDate = $blockDateInput.val();

			if (expirationDate)
				expirationDate = new Moment(expirationDate, "DD.MM.YYYY").format("YYYY-MM-DD");

			else
				expirationDate = null;

			let response = JSON.parse(await $.post(`${BASE_URL}user/updateExpiryDate`, {
				expirationDate,
				idUser: selectedIdUser
			}));

			if (response.success) {
				VanillaToasts.create({
					text: response.message,
					timeout: 3000
				});

				closeModal();
			}
		}

		function resetUserExpiryDate() {
			$blockDateInput.val("");
			$modal.find(".hint").fadeIn();
		}

		function showModal() {
			populateModal();
			$modal.fadeIn({
				start: function () {
					$(this).css("display", "flex");
				}
			});
		}

		async function populateModal() {
			let expirationDate = await fetchUserExpirationDate();
			if (expirationDate)
				$(".modal .hint").hide();
			else
				$(".modal .hint").show();
			$blockDateInput.val(expirationDate);

		}

		async function fetchUserExpirationDate() {

			let response = JSON.parse(await $.post(`${BASE_URL}user/getExpirationDate`, { idUser: selectedIdUser }));
			if (response.expirationDate !== "0000-00-00" && response.expirationDate !== null)
				return new Moment(response.expirationDate).format("DD.MM.YYYY");
			return "";
		}

		function closeModal() {
			$modal.fadeOut();
		}

		function toggleRowSelection(){
			
			$(this).closest("tr").toggleClass("selected");
		}

		// ******** LISTENERS ***********

		$(".app").on("click", ".row-add .add", addUser);
		$(".app").on("mousedown", "tr[data-id-user]", setSelectedIdUser);
		$(".app").on("change keyup", "tr[data-id-user] input:not([type=checkbox]), tr[data-id-user] select", showOptions);
		$(".app").on("change", "tr[data-id-user] input[type=checkbox]", toggleRowSelection)
		$(".app").on("click", "tr[data-id-user] .undo", undoChanges);
		$(".app").on("click", "tr[data-id-user] .save", updateUser);
		$(".app").on("click", "tr[data-id-user] .delete", deleteUser);
		$(".app").on("click", "tr[data-id-user] .block", showModal);
		$(".app").on("click", ".modal .save", updateUserExpiryDate);
		$(".app").on("click", ".modal .abort", closeModal);
		$(".app").on("click", ".modal .undo", resetUserExpiryDate);
	}
});
