import { EventBus } from "./pictureValidator";
export var ToolbarComponent = {
    props: {
        allProjectsCollapsed: Boolean,
        canExport: Boolean
    },
    data: function () {
        return {
            uploading: false
        };
    },
    methods: {
        toggleSelectAll: function () {
            this.$emit('on-toggle-select-all');
        },
        exportAssets: function () {
            this.uploading = true;
            EventBus.$emit('on-export');
        }
    },
    template: "\n        <div class='toolbar'>\n            <div class='left'>\n                <img \n                    v-if='allProjectsCollapsed'\n                    @click=\"$emit('toggle-collapse')\"\n                    src='" + BASE_URL + "src/assets/icons/expandedIcon.svg'>\n                <img \n                v-if='!allProjectsCollapsed'\n                @click=\"$emit('toggle-collapse')\"\n                src='" + BASE_URL + "src/assets/icons/collapsedIcon.svg'>\n\n                <button\n                    class='select-all-button'\n                    @click='toggleSelectAll'\n                    >S\u00E9lectionner tous</button>\n            </div>\n            <i\n                class=\"fas fa-plus-circle add-project-button\"\n\t\t\t\t@click=\"$emit('on-new-project')\"></i>\n\t\t\t\t<div class='export-container'>\n\t\t\t\t\t<button\n\t\t\t\t\t\tclass='export-button'\n\t\t\t\t\t\t:class=\"{disabled: !canExport}\"\n\t\t\t\t\t\tv-if=\"!uploading\"\n\t\t\t\t\t\t@click='exportAssets'>Exporter</button>\n\t\t\t\t\t<span\n\t\t\t\t\tv-if=\"uploading\">T\u00E9l\u00E9chargement...</span>\n\t\t\t\t</div>\n        </div>\n\t",
    created: function () {
        var _this = this;
        EventBus.$on('on-export-done', function () {
            _this.uploading = false;
        });
    }
};
