/**
 * Author: Jim Schmit
 * Attaches an dropdown menu to a given text input field.
 * As the user types in text, a list with the results is shown.
 * 
 * Event on the input element emitted after an element is clicked in the resultlist: entrySelected
 */

export default function(settings = {
	entrySearchInput: undefined, // the entry search input element
	availableEntries: undefined, // array of the elements to show (each element needs a value field with the actual value to show in the result list)
	nameOfIdField: undefined, // if an id is needed, will be placed as an data-id attribute
	nameOfValueField: undefined, // the name of the value field
	placeResultElAfter: undefined // where to position the result list
}){
	let entrySearchInput = settings.entrySearchInput;
	let availableEntries = settings.availableEntries;
	let nameOfIdField = settings.nameOfIdField;
	let nameOfValueField = settings.nameOfValueField;
	let placeResultElAfter = settings.placeResultElAfter || entrySearchInput;

	let resultContainerEl = document.createElement('ul');
	resultContainerEl.classList.add('entry-search-results')
		
		

	// SETUP
	//add entry search result element after the input field
	entrySearchInput.parentNode.insertBefore(resultContainerEl, placeResultElAfter.nextSibling);


	//add event listeners
	entrySearchInput.addEventListener("keyup", entryInput);
	
	
	$(entrySearchInput).on("click", displayEntryResults.bind(this, availableEntries));
	entrySearchInput.addEventListener("blur", function () {
		hideEntryResults();
	});

	
	async function entryInput(e) {
		let inputText = this.value;	
		
		let resultEntries = availableEntries.filter(function (el) {
			return el[nameOfValueField].toLowerCase().indexOf(inputText.toLowerCase(), 0) >= 0;
		});
			
	
		displayEntryResults(resultEntries);
	}

	function generateEvent(entry) {
		return new CustomEvent(
			"entrySelected",
			{
				"detail": {
					"selectedEntry": entry,
				}
			});
	}
	
	
	function displayEntryResults(entries) {
			
		// clear current results
		resultContainerEl.innerHTML = "";
	
	
		// create template entry
		let templateEntry = document.createElement("li");
	
		//process results
		entries.forEach(function (entry) {
	
			let entryEl = templateEntry.cloneNode(true);
	
			// set your data attribute name and the value
			entryEl.setAttribute("data-id", entry[nameOfIdField]);
	
			// set the text to display in the list entry
			
			entryEl.innerHTML = entry[nameOfValueField];
	
			// add click listener
			entryEl.addEventListener("mousedown", function () {
	
				// set the value of the search field
				entrySearchInput.value = entry[nameOfValueField]
				
				//create event
				let entrySelectedEvent = generateEvent(entry);
				
				// fire event
				entrySearchInput.dispatchEvent(entrySelectedEvent);
	
				// hide the results
				resultContainerEl.style.display = "none";
	
				// set data attribute of the search field
				entrySearchInput.setAttribute("data-selected-id", entry[nameOfIdField]);
			
			});
	
			//adding the entry to the search results
			resultContainerEl.appendChild(entryEl);
	
		});
	
		// show the results
		resultContainerEl.style.display = "block";
	}
	
	function hideEntryResults() {
		setTimeout(() => {
			resultContainerEl.style.display = "none";
	
		}, 50);
	}

	return {
		displayEntryResults
	}
};


