$(() => {
	if ($("[data-page='importer']").length) {

		// ********** VARIABLES *************

		let $homePage = $(".home-page");
		let $resultPage = $(".result-page");
		let $diffPage = $(".diff-page");
		let $usersImport = $(".users-import");
		let $projectsImport = $(".projects-import");
		let $clientsImport = $(".customers-import");
		let $cleanupButton = $('.cleanup-button');	
		let chosenCsv;
		let mode;
		let totalImportedItems;

		const CSV = {
			users: "users",
			projects: "projects",
			customers: "customers"
		};

		let $csvInput = $(".csv-input");


		// ************ SETUP ***************


		// ********** LISTENERS *************

		// $(".home-page").click(slideToResultPage)
		// $(".result-page").click(slideToHomePage)

		$(".bubble.users-import, .mode-chooser .bubble").on("click",showFileUpload);
		$(".bubble-activate.users-import, .mode-chooser .bubble-activate").on("click", promptToActivate);
		$csvInput.change(processFile);
		$(".back").on("click",slideToHomePage);
		$cleanupButton.on("click",cleanup);


		// ********** FUNCTIONS *************

		function slideToResultPage() {

			$homePage.css("transform", "translateX(-100%)");
			$resultPage.css("transform", "translateX(0)");
		}
		function slideToHomePage() {
			$homePage.css("transform", "translateX(0)");
			$resultPage.css("transform", "translateX(100%)");
			$diffPage.css("transform", "translateX(200%)");
		}
		function slideToDiffPage() {
			$homePage.css("transform", "translateX(-100%)");
			$diffPage.css("transform", "translateX(0)");
		}

		function showFileUpload() {
			
			let categorie = $(this).data('category');
			mode = $(this).text().toLowerCase().replace(" ", "");
			switch (categorie) {
				case "users":
					chosenCsv = CSV.users;
					break;
				case "projects":
					chosenCsv = CSV.projects;
					break;
				case "customers":
					chosenCsv = CSV.customers;
					break;
				default:
					break;
			}
			$csvInput.click();
		}

		function processFile() {

			// show loader
			let loader = document.querySelector('.loader');
			loader.classList.add('show');

			var fd = new FormData();
			var csvFile = $csvInput[0].files[0];

			fd.append('csvFile', csvFile);
			fd.append('importType', chosenCsv);
			fd.append('mode', mode);

			$.post({
				url: `${BASE_URL}importer/importCsv`,
				data: fd,
				contentType: false,
				processData: false,
				success: response => {
					
					response = JSON.parse(response)
					if(response.success){
						if(mode === "cleanup"){
							slideToDiffPage();
							if(chosenCsv === CSV.projects){
								populateDiffSection(response.projectsToDelete, true);
								
							}
							else{
								populateDiffSection(response.customersToDelete, false);
							}
						}
						else{
							totalImportedItems = response.totalImports;
							populateResultSection();
							slideToResultPage();
							VanillaToasts.create({
								text: response.message,
								timeout: 5000,
								icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
							});
						}
					}
					else{
						VanillaToasts.create({
							text: response.message,
							type: 'error',
							timeout: 5000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});
					}
					loader.classList.remove('show');
					
					$csvInput.val("");
					
				},
			});
		}

		function populateResultSection(){
			$(".total-imported-entries").text(totalImportedItems);
			$(".csv-category").text(chosenCsv);
		}

		function populateDiffSection(entries, isProjects){

			if(isProjects){
				// remove old entries
				$('.project-diff table tr:gt(1)').remove();
				entries.forEach(project => {
					let $template = $('.project-diff .template').clone();
	
					$template.removeClass('template').attr('data-id-project', project.idProject);
					$template.find('td').eq(1).text(project.idProject);
					$template.find('td').eq(2).text(project.combinedHandle);
					$template.find('td').eq(3).text(project.dtSubject);
					$template.find('td').eq(4).text(project.dtAddress);
					$template.find('td').eq(5).text(project.dtLocality);
					
	
					$('.project-diff table').append($template);
				});

			}
			else{

			}
			
			
		}

		function cleanup(){
			if(!confirm('Supprimer les projets selectés?'))
				return;

			if(chosenCsv === CSV.projects){
				let ids = $('.project-diff input:checked').map((i, el) => {
					
					return $(el).closest('tr').data('id-project')
				}).toArray();
				
				$.post(`${BASE_URL}importer/markProjectsInactive`, {
					ids
				}, () => {
					VanillaToasts.create({
						text: 'Supprimation avec succés',
						type: 'success',
						timeout: 2000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
					setTimeout(() => {
						location.reload();
					}, 3000)
				});
				
			}
			
			
			else{
				let ids = $('.customer-diff input:checked').map((i, el) => {
					
					return $(el).closest('tr').data('id-customer')
				}).toArray();
				$.post(`${BASE_URL}importer/removeCustomers`, {
					ids
				}, () => {
					location.reload();
				});
				
			}
			
		}

		function promptToActivate() {
			let id = prompt('Project\'s ID : ');
			
			$.post(`${BASE_URL}importer/markProjectsActive`, {
				id
			}, () => {
				VanillaToasts.create({
					text: 'succés',
					type: 'success',
					timeout: 2000,
					icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
				});
			});

		}
	}
});
