import * as moment from 'moment';
if($("[data-page='holidays']").length){

	$(()=> {

		// *****************  VARIABLES *******************************

		let $startInput = $(".date-wrapper .start-date input");
		let $endInput = $(".date-wrapper .end-date input");
		let $eventTitle = $(".holiday-add .event-title");

		let entries = [];

		// *****************  SETUP ***********************************
		let datepickerOptions = {
			format: "dd.mm.yyyy",
			autoHide: true,
		};

		// new entry date fields
		$startInput.datepicker({
			...datepickerOptions,
			trigger: $(".start-date")
		});
		$endInput.datepicker({
			...datepickerOptions,
			trigger: $(".end-date")
		});


		// existing entries date fields
		$(".listed-holidays .start-date input").each((i, el) => {
			$(el).datepicker({
				...datepickerOptions,
				trigger: $(el).parent()
			});
		});
		$(".listed-holidays .end-date input").each((i, el) => {
			$(el).datepicker({
				...datepickerOptions,
				trigger: $(el).parent()
			});
			$(el).datepicker();
		});

		// *****************  FUNCTIONS *******************************
		function handleStartDateChange() {
			if (this.value === "" || $eventTitle.val() === "") {
				$(".add").attr("disabled", "");
			}
			else{
				$(".add").attr("disabled", null);
			}
			$startInput.data("date", moment(this.value, "DD.MM.YYYY").format("YYYY-MM-DD"));
			$endInput.datepicker('setStartDate', new Date($startInput.data("date")));
			$endInput.val($startInput.val());
			$endInput.datepicker('show');
		}

		function handleTitleChange(){
			if (this.value === "" || $eventTitle.val() === "") {
				$(".add").attr("disabled", "");
				return;
			}
			$(".add").attr("disabled", null);
		}

		function handleExistingEntryChange(){

			let $entry = $(this).closest(".entry");

			if(this.className.indexOf('date'))
				$(this).data("date", moment(this.value, "DD.MM.YYYY").format("YYYY-MM-DD"));

			let updatedEntry = {
				idDateRangeEvent: $entry.data("id-daterange-event"),
				startDate: $entry.find(".start-date input").data('date'),
				endDate: $entry.find(".end-date input").data('date'),
				title: $entry.find(".event-title").val(),
				toUpdate: true
			}
			let found = false;
			entries.forEach((entry, i) => {
				if(entry.idDateRangeEvent === updatedEntry.idDateRangeEvent){
					entries[i] = updatedEntry
					found = true;
				}
			});
			if(!found)
				entries.push(updatedEntry);


		}

		function addEntryToList(){
			let entry = {
				startDate: $startInput.data("date"),
				endDate: $endInput.data("date"),
				title: $eventTitle.val(),
				toAdd: true
			}

			console.log(entry);
			entries.push(entry);

			// append entry to view
			let $template = $(".template").clone();
			$template.removeClass("template");

			let $start = $template.find(".start-date input");
			let $end = $template.find(".end-date input");
			let $delete = $template.find(".delete");

			$start.data("date", $startInput.data('date'));
			$end.data("date", $endInput.data('date'));

			// add datepickers
			$start.val($startInput.val()).datepicker({
				...datepickerOptions,
				trigger: $start.parent()
			});
			$end.val($endInput.val()).datepicker({
				...datepickerOptions,
				trigger: $end.parent()
			});
			$template.find(".event-title").val($eventTitle.val());

			// add change listeners
			$start.change(()=>{
				$start.data("date", moment($start.val(), "DD.MM.YYYY").format("YYYY-MM-DD"));
				entry.startDate = $start.data('date');

			});
			$end.change(()=>{
				$end.data("date", moment($end.val(), "DD.MM.YYYY").format("YYYY-MM-DD"));
				entry.endDate = $end.data('date');
			});
			$eventTitle.change(()=>{
				entry.title = $eventTitle.val()
			});

			$delete.click(() => {
				entries = entries.filter(el => el !== entry);
				$template.fadeOut();
			})


			$(".new-entries").prepend($template).hide().fadeIn();

			[$startInput, $endInput, $eventTitle].forEach($el => $el.val(""));
			$(".add").attr("disabled", "");
		}

		async function saveEntries(){
			await $.post(`${BASE_URL}holidays/saveEntries`, {entries});
			location.reload();
		}

		function deleteEntry(){
			let $entry = $(this).closest(".entry");
			let idDateRangeEvent = $entry.data("id-daterange-event");

			entries.push({
				idDateRangeEvent,
				toDelete: true
			});
			$entry.fadeOut();
		}

		function displayFileName(){

			$(".selected-file").text(this.files[0].name);
			$(".upload").removeClass("disabled");
		}

		async function uploadFile(){
			var fd = new FormData();
			var file = $(".file-upload")[0].files[0];

			fd.append('file', file);

			$.post({
				url: `${BASE_URL}holidays/importFile`,
				data: fd,
				contentType: false,
				processData: false,
				success: response => {
					response = JSON.parse(response)
					if(response.success){

						VanillaToasts.create({
							text: response.message,
							timeout: 5000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});

						setTimeout(() => location.reload(), 3000);
					}
					else{
						VanillaToasts.create({
							text: response.message,
							type: 'error',
							timeout: 5000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});
					}

					$(".loader").hide();

				},
			});
			$(".loader").show();
		}


		// *****************  LISTENERS *******************************


		// add changelisteners to date fields and title input
		$startInput.change(handleStartDateChange);
		$endInput.change(function () {
			$(this).data("date", moment(this.value, "DD.MM.YYYY").format("YYYY-MM-DD"));
		});
		$(".holiday-add .event-title").keyup(handleTitleChange);

		$(".add").click(addEntryToList);
		$(".save").click(saveEntries);
		$(".old-entries .delete").click(deleteEntry);
		$(".old-entries input").change(handleExistingEntryChange);
		$(".open-file-upload").click(() => $(".file-upload").click());
		$(".file-upload").change(displayFileName);
		$(".upload").click(uploadFile);
	});
}
