var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-ignore
import Litepicker from 'litepicker';
import Users from '../models/users';
import Project from '../models/projects';
import TeamWrapper from '../models/teambox';
import Nav from '../_navigation';
var Timetracker = /** @class */ (function () {
    function Timetracker() {
        var _this = this;
        this.exportCount = 0;
        this.validateCount = 0;
        this.exportedEntries = [];
        this.incrementValidationCount = function () {
            _this.validateCount++;
            document.querySelector('.validate-count').textContent = "" + _this.validateCount;
            if (_this.validateCount > 0) {
                _this.validateButtonEl.classList.add('active');
            }
        };
        this.decrementValidationCount = function () {
            _this.validateCount--;
            document.querySelector('.validate-count').textContent = "" + _this.validateCount;
            if (_this.validateCount === 0) {
                _this.validateButtonEl.classList.remove('active');
            }
        };
        this.isCurrentDateSelected = true;
        this.isAlertActive = false;
        this.workers = Users.getWorkers();
        this.projects = Project.getProjects();
        this.setup();
    }
    ;
    Timetracker.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.exportButtonEl = document.querySelector('.export');
                        this.validateButtonEl = document.querySelector('.top-row .validate');
                        this.alertButtonEl = document.querySelector('.alert-button');
                        this.dateToggleEl = document.querySelector('.date-toggle');
                        this.exportCheckEl = document.getElementById('export-checked');
                        _a = this;
                        _c = (_b = TeamWrapper).getTeamWrappers;
                        return [4 /*yield*/, this.projects];
                    case 1:
                        _d = [_e.sent()];
                        return [4 /*yield*/, this.workers];
                    case 2:
                        _a.teamWrappers = _c.apply(_b, _d.concat([_e.sent(),
                            this.setActiveTeamWrapper,
                            this.incrementValidationCount,
                            this.decrementValidationCount]));
                        this.validateCount = document.querySelectorAll('.overflow-container.collapsed').length;
                        this.exportButtonEl.addEventListener('click', function () { return _this.export(); });
                        this.validateButtonEl.addEventListener('click', function () { return _this.slideToExportScreen(); });
                        this.alertButtonEl.addEventListener('click', function () { return _this.toggleAlertEntries(); });
                        this.dateToggleEl.addEventListener('click', function (e) { _this.dateToggleHandler(); });
                        this.litePicker = new Litepicker({
                            element: this.dateToggleEl,
                            singleMode: false,
                            lang: 'fr',
                            tooltipText: {
                                one: 'jour',
                                other: 'jours'
                            },
                            onSelect: this.selectDateRange.bind(this),
                        });
                        this.exportCheckEl.addEventListener('change', function (e) { _this.handleMarkExportedEntries(); });
                        return [2 /*return*/];
                }
            });
        });
    };
    Timetracker.prototype.setActiveTeamWrapper = function (teamWrapper) {
        this.activeTeamWrapper = teamWrapper;
    };
    Timetracker.prototype.handleMarkExportedEntries = function () {
        if (this.exportCheckEl.checked)
            this.confirmExported();
        else
            this.unconfirmExported();
    };
    Timetracker.prototype.slideToExportScreen = function () {
        // slide all non validated team entries to the left
        var nonValidatedTeams = Array.from(document.querySelectorAll('.overflow-container:not(.collapsed)')).map(function (el) {
            var teamWrapper = el.parentElement.parentElement;
            teamWrapper.classList.add('slide-out-left');
            teamWrapper.addEventListener('animationend', function () { return teamWrapper.classList.add('hide'); });
            return teamWrapper;
        });
        // remove all dates with their entries where no validated team entry exists
        Array.from(document.querySelectorAll('.date')).forEach(function (day) {
            if (day.querySelectorAll('.team-wrapper:not(.slide-out-left)').length === 0) {
                day.addEventListener('animationend', function () { return day.classList.add('hide'); });
                day.classList.add('slide-out-left');
            }
        });
        // make all fields non editable for validated teams and replace valider text with modify text
        var validatedTeams = Array.from(document.querySelectorAll('.overflow-container.collapsed')).forEach(function (el) {
            var teamWrapper = el.parentElement.parentElement;
            var validateButton = teamWrapper.querySelector('.validate-button');
            var resetButton = teamWrapper.querySelector('.reset-button');
            var modifyButton = teamWrapper.querySelector('.modify-button');
            var isExpanded = false;
            // hide validate button, show modify button
            validateButton.classList.add('hide');
            modifyButton.classList.remove('hide');
            // hide reset button
            resetButton.classList.add('hide');
            modifyButton.addEventListener('click', function () {
                modifyButton.closest('.header').nextElementSibling.classList.toggle('collapsed');
            });
        });
        // slide alert and date toggle button to the left
        this.dateToggleEl.classList.add('slide-out-left');
        this.alertButtonEl.classList.add('slide-out-left');
        this.exportButtonEl.classList.remove('hide');
        this.validateButtonEl.classList.add('hide');
        this.exportCount = this.validateCount;
        this.exportButtonEl.querySelector('.export-count').textContent = this.exportCount + '';
    };
    Timetracker.prototype.toggleAlertEntries = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showLoader();
                        if (!!this.isAlertActive) return [3 /*break*/, 2];
                        return [4 /*yield*/, $.get('timetracker/alerts')];
                    case 1:
                        response = _a.sent();
                        document.querySelector('.app').innerHTML = response;
                        this.setup();
                        this.alertButtonEl.classList.add('active');
                        return [3 /*break*/, 3];
                    case 2:
                        this.showCurrentDateEntries();
                        this.setup();
                        this.alertButtonEl.classList.remove('active');
                        _a.label = 3;
                    case 3:
                        this.hideLoader();
                        this.isAlertActive = !this.isAlertActive;
                        return [2 /*return*/];
                }
            });
        });
    };
    Timetracker.prototype.dateToggleHandler = function () {
        if (this.isCurrentDateSelected === true) {
            this.litePicker.show();
        }
        else {
            this.litePicker.hide();
            this.showCurrentDateEntries();
        }
        this.isCurrentDateSelected = !this.isCurrentDateSelected;
    };
    Timetracker.prototype.selectDateRange = function (date1, date2) {
        return __awaiter(this, void 0, void 0, function () {
            var startDate, endDate, response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        startDate = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();
                        endDate = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
                        this.isCurrentDateSelected = false;
                        this.showLoader();
                        return [4 /*yield*/, $.post('timetracker/dateRange', {
                                startDate: startDate,
                                endDate: endDate
                            })];
                    case 1:
                        response = _a.sent();
                        document.querySelector('.app').innerHTML = response;
                        this.setup();
                        setTimeout(function () {
                            _this.dateToggleEl.classList.add('date-range');
                        }, 0);
                        this.hideLoader();
                        return [2 /*return*/];
                }
            });
        });
    };
    Timetracker.prototype.showCurrentDateEntries = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showLoader();
                        return [4 /*yield*/, $.get('timetracker')];
                    case 1:
                        response = _a.sent();
                        document.body.innerHTML = response;
                        this.setup();
                        new Nav();
                        return [2 /*return*/];
                }
            });
        });
    };
    Timetracker.prototype.export = function () {
        return __awaiter(this, void 0, void 0, function () {
            var teamWrappers, userEntries, file, anchor, date, month, formattedDate;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        teamWrappers = Array.from(document.querySelectorAll('.team-wrapper:not(.slide-out-left)'));
                        userEntries = [];
                        this.exportedEntries = [];
                        teamWrappers.forEach(function (team) {
                            var date = team.getAttribute('data-date');
                            var idCE = parseInt(team.getAttribute('data-id-ce'));
                            _this.exportedEntries.push({
                                date: date, idCE: idCE
                            });
                            // get each user row
                            var userRows = Array.from(team.querySelectorAll('.user-row'));
                            userRows.forEach(function (row) {
                                var idUser = parseInt(row.querySelector('.user-label').getAttribute('data-id-user'));
                                // get each project/hours container
                                var projectHourContainers = Array.from(row.querySelectorAll('.project-entry'));
                                projectHourContainers.forEach(function (projectEntry) {
                                    var idProject = parseFloat(projectEntry.querySelector('.project').getAttribute('data-id-project'));
                                    var hours = parseFloat(projectEntry.querySelector('input').value);
                                    if (hours === 0)
                                        return;
                                    userEntries.push({
                                        date: date,
                                        idUser: idUser,
                                        idProject: idProject,
                                        idCE: idCE,
                                        hours: hours
                                    });
                                });
                            });
                        });
                        return [4 /*yield*/, this.createCsv(userEntries)];
                    case 1:
                        file = _a.sent();
                        anchor = document.createElement('a');
                        date = new Date();
                        month = date.getMonth() + 1;
                        if (month.toString().length === 1)
                            month = '0' + month;
                        formattedDate = date.getFullYear() + "_" + month + "_" + date.getDate();
                        anchor.href = file;
                        anchor.download = formattedDate + '_workhoursExport.csv';
                        anchor.click();
                        // show mark entries exported toggle
                        this.exportCheckEl.parentElement.parentElement.classList.remove('hidden');
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Manually confirm that the current entries in export section are exported
     */
    Timetracker.prototype.confirmExported = function () {
        $.post(BASE_URL + "timetracker/markTeamEntriesExported", {
            teamEntries: this.exportedEntries
        }, function () {
            VanillaToasts.create({
                text: "Marked entries as exported",
                type: 'success',
                timeout: 3000,
                icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
            });
        });
    };
    /**
     * Manually confirm that the current entries in export section are no longer exported
     */
    Timetracker.prototype.unconfirmExported = function () {
        $.post(BASE_URL + "timetracker/markTeamEntriesNotExported", {
            teamEntries: this.exportedEntries
        }, function () {
            VanillaToasts.create({
                text: "Marked entries as not exported",
                type: 'success',
                timeout: 3000,
                icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
            });
        });
    };
    Timetracker.prototype.showLoader = function () {
        document.querySelector('.loader').classList.add('show');
    };
    Timetracker.prototype.hideLoader = function () {
        document.querySelector('.loader').classList.remove('show');
    };
    Timetracker.prototype.createCsv = function (entries) {
        return __awaiter(this, void 0, void 0, function () {
            var exportString, workers, textFile, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        exportString = 'User,Date,Project,Notes,Hours\r\n';
                        return [4 /*yield*/, this.workers];
                    case 1:
                        workers = _a.sent();
                        entries.forEach(function (entry) {
                            var date = entry.date, idUser = entry.idUser, idProject = entry.idProject, idCE = entry.idCE, hours = entry.hours;
                            var handle = workers.find(function (worker) { return worker.idUser === idUser + ''; }).dtHandle;
                            exportString += "\"" + handle + "\",\"" + date + "\",\"" + idProject + "\",,\"" + hours + "\"\r\n";
                        });
                        textFile = null;
                        data = new Blob([exportString], { type: 'text/csv' });
                        // If we are replacing a previously generated file we need to
                        // manually revoke the object URL to avoid memory leaks.
                        if (textFile !== null)
                            window.URL.revokeObjectURL(textFile);
                        textFile = window.URL.createObjectURL(data);
                        // returns a URL you can use as a href
                        return [2 /*return*/, textFile];
                }
            });
        });
    };
    return Timetracker;
}());
if (document.querySelector('[data-page=timetracker]')) {
    window.onload = function () { return new Timetracker(); };
}
