var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Observer } from "../tools/observer";
import DropdownMenu from "../_dropdownMenu";
// @ts-ignore
import VanillaToasts from 'vanillatoasts';
import { ConfirmModal } from "../tools/confirmModal";
var Task = /** @class */ (function () {
    function Task(text, taskEl, idTripTask, drivers, availableTasks, trip) {
        this.taskInputEl = taskEl.querySelector('input');
        this.idTripTask = idTripTask,
            this.text = text;
        this.previousText = text;
        this.drivers = drivers.map(function (driver) {
            return driver.clone();
        });
        this.availableTasks = availableTasks;
        this.idTrip = parseInt(taskEl.closest('.project').getAttribute('data-id-trip'));
        this.isDepot = taskEl.querySelector('input[type="checkbox"]').checked;
        this.trip = trip;
        this.setupTask(taskEl);
    }
    Object.defineProperty(Task.prototype, "idTripTask", {
        get: function () {
            return this._idTripTask;
        },
        set: function (value) {
            this._idTripTask = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Task.prototype, "drivers", {
        get: function () {
            return this._drivers;
        },
        set: function (value) {
            this._drivers = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Task.prototype, "text", {
        get: function () {
            return this._text;
        },
        set: function (value) {
            this.previousText = this.text;
            this._text = value;
            this.taskInputEl.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Task.prototype.updateTaskFunction = function () {
        var _this = this;
        if (this.text !== this.taskInputEl.value) {
            this.text = this.taskInputEl.value;
            if (this.text)
                this.updateTask();
            else {
                var canDeleteTask = !this.drivers.some(function (driver) {
                    return driver.status !== DriverTaskStatus.notTaken;
                });
                if (canDeleteTask) {
                    new ConfirmModal({
                        modalQuestion: 'Supprimer cette tâche?',
                        confirmText: 'Oui, supprimer',
                        denyText: 'Non',
                        cb: (function (confirmed) {
                            confirmed ? _this.deleteTask() : _this.text = _this.previousText;
                        })
                    });
                    // this.deleteTask();
                }
            }
        }
    };
    /**
     * Returns all the available tasks
     */
    Task.getAvailableTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tasks, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, $.post(BASE_URL + 'trip/getAvailableTasks')];
                    case 1:
                        tasks = _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/, tasks];
                }
            });
        });
    };
    Task.fromElement = function (task, availableDrivers, availableTasks, trip) {
        var text = task.querySelector('input').value;
        var idTripTask = task.getAttribute('data-id-trip-task');
        if (idTripTask === '')
            idTripTask = null;
        else
            idTripTask = parseInt(idTripTask);
        // get the states of the task (from each driver)
        var states = Array.from(task.querySelectorAll('.status-button')).map(function (status) {
            var statusString = status.getAttribute('data-status');
            switch (statusString) {
                case 'notTaken':
                    return DriverTaskStatus.notTaken;
                case 'taken':
                    return DriverTaskStatus.taken;
                case 'done':
                    return DriverTaskStatus.done;
                case 'offered':
                    return DriverTaskStatus.offered;
                case 'rejected':
                    return DriverTaskStatus.rejected;
                    break;
                default:
                    return DriverTaskStatus.notTaken;
            }
        });
        // assign the status of the task to each driver
        availableDrivers.forEach(function (driver, i) {
            driver.status = states[i];
        });
        return new Task(text, task, idTripTask, availableDrivers, availableTasks, trip);
    };
    /**
     * Registers the listeners for status and setups the dropdown for tasks
     * @param taskRow
     */
    Task.prototype.setupTask = function (taskRow) {
        return __awaiter(this, void 0, void 0, function () {
            var dd, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        // setup status container click listener
                        taskRow.querySelectorAll('.status-button').forEach(function (button, i) {
                            var _a;
                            var currentDriver = (_a = _this) === null || _a === void 0 ? void 0 : _a.drivers[i];
                            var statusObserver = new Observer('status', (function (newStatus) {
                                var className = DriverTaskStatus[newStatus].replace(/([A-Z])/g, function (g) { return "-" + g[0].toLowerCase(); });
                                button.className = "status-button " + className;
                            }));
                            currentDriver.subscribe(statusObserver);
                            button.addEventListener('click', function () {
                                var _a;
                                var currentDriverStatus = (_a = currentDriver) === null || _a === void 0 ? void 0 : _a.status.valueOf();
                                if (currentDriverStatus !== null) {
                                    currentDriver.status = currentDriverStatus === 4 || currentDriverStatus > 1 ? 0 : currentDriverStatus + 1;
                                    console.log('set driver status to ' + DriverTaskStatus[currentDriver.status]);
                                    _this.setDriverstatus(currentDriver.status, currentDriver.idUser);
                                }
                            });
                        });
                        // setup depot listener
                        taskRow.querySelector('input[type="checkbox"]').addEventListener('change', function () { _this.toggleDepot(); });
                        // setup task list dropdown
                        this.taskInputEl.addEventListener('focus', function () { return _this.taskInputEl.parentElement.style.zIndex = "2"; });
                        this.taskInputEl.addEventListener('blur', function () { return _this.taskInputEl.parentElement.style.zIndex = ""; });
                        // save predefined task on CTRL + ENTER
                        this.taskInputEl.addEventListener('keypress', function (e) { return __awaiter(_this, void 0, void 0, function () {
                            var label_1, predefinedTaskExists;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(e.code === 'Enter' && e.ctrlKey)) return [3 /*break*/, 2];
                                        label_1 = e.target.value;
                                        if (!label_1) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.availableTasks];
                                    case 1:
                                        predefinedTaskExists = (_a.sent()).some(function (predefinedTask) { return predefinedTask['dtLabel'] === label_1; });
                                        if (predefinedTaskExists)
                                            return [2 /*return*/];
                                        $.post(BASE_URL + "trip/addPredefinedTask", {
                                            label: label_1
                                        });
                                        VanillaToasts.create({
                                            text: 'Nouvelle tâche de base ajoutée',
                                            type: 'success',
                                            timeout: 3000,
                                            icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
                                        });
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        // remove predefined task on SHIFT + DEL
                        this.taskInputEl.addEventListener('keyup', function (e) { return __awaiter(_this, void 0, void 0, function () {
                            var label_2, predefinedTaskExists;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(e.code === 'Delete' && e.shiftKey)) return [3 /*break*/, 2];
                                        label_2 = e.target.value;
                                        if (!label_2) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.availableTasks];
                                    case 1:
                                        predefinedTaskExists = (_a.sent()).some(function (predefinedTask) { return predefinedTask['dtLabel'] === label_2; });
                                        if (!predefinedTaskExists)
                                            return [2 /*return*/];
                                        $.post(BASE_URL + "trip/removePredefinedTask", {
                                            label: label_2
                                        });
                                        VanillaToasts.create({
                                            text: 'Tâche de base suprimée',
                                            type: 'success',
                                            timeout: 3000,
                                            icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
                                        });
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        _a = DropdownMenu;
                        _b = {
                            entrySearchInput: this.taskInputEl
                        };
                        return [4 /*yield*/, this.availableTasks];
                    case 1:
                        dd = _a.apply(void 0, [(_b.availableEntries = _c.sent(),
                                _b.nameOfValueField = 'dtLabel',
                                _b.nameOfIdField = 'idPredefinedTask',
                                _b.placeResultElAfter = undefined,
                                _b)]);
                        // dropdown entry selected, update/create task
                        this.taskInputEl.addEventListener('entrySelected', (function (data) {
                            var selectedEntry = data.detail.selectedEntry;
                            var label = _this.text;
                            _this.text = selectedEntry.dtLabel;
                            if (!label) {
                                _this.createTask();
                            }
                            else {
                                _this.updateTask();
                            }
                        }));
                        // update/delete task on focus out of textfield
                        this.taskInputEl.addEventListener('blur', function (e) {
                            _this.updateTaskFunction();
                        });
                        this.taskInputEl.addEventListener('keypress', function (e) {
                            if (e.key === 'Enter') {
                                _this.updateTaskFunction();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * adds a task to a trip
     */
    Task.prototype.createTask = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, taskRowNeeded;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        _b = parseInt;
                        return [4 /*yield*/, $.post(BASE_URL + "trip/addTaskToTrip", {
                                idTrip: this.idTrip,
                                label: this.text
                            })];
                    case 1:
                        _a.idTripTask = _b.apply(void 0, [_c.sent()]);
                        taskRowNeeded = !this.trip.tasks.some(function (task) { return task.idTripTask === null; });
                        if (taskRowNeeded) {
                            this.trip.addEmptyTaskRow();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * adds a task to a trip if it does not yet exists, otherwise updates it
     */
    Task.prototype.updateTask = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.idTripTask) {
                            this.createTask();
                            return [2 /*return*/];
                        }
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, $.post(BASE_URL + "trip/updateTask", {
                                idTripTask: this.idTripTask,
                                label: this.text
                            })];
                    case 1:
                        response = _b.apply(_a, [_c.sent()]);
                        !response.success ?
                            VanillaToasts.create({
                                text: response.message,
                                type: 'error',
                                timeout: 3000,
                                icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
                            }) : null;
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Removes a task from a trip
     */
    Task.prototype.deleteTask = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $.post(BASE_URL + "trip/deleteTask", {
                    idTripTask: this.idTripTask,
                });
                this.idTripTask = null;
                this.drivers.forEach(function (driver) {
                    driver.status = DriverTaskStatus.notTaken;
                });
                return [2 /*return*/];
            });
        });
    };
    /**
     * Toggles the depot status of a trip task
     */
    Task.prototype.toggleDepot = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.idTripTask)
                    return [2 /*return*/];
                this.isDepot = !this.isDepot;
                $.post(BASE_URL + "trip/toggleDepot", {
                    idTripTask: this.idTripTask
                });
                return [2 /*return*/];
            });
        });
    };
    /**
     *
     * @param status the new status of the driver
     * @param idDriver the id of the driver to update the status of
     */
    Task.prototype.setDriverstatus = function (status, idDriver) {
        return __awaiter(this, void 0, void 0, function () {
            var stringStatus;
            return __generator(this, function (_a) {
                stringStatus = DriverTaskStatus[status];
                if (stringStatus === 'notTaken')
                    stringStatus = 'not taken';
                $.post(BASE_URL + "trip/setDriverStatus", {
                    idTripTask: this.idTripTask,
                    idDriver: idDriver,
                    status: stringStatus
                });
                return [2 /*return*/];
            });
        });
    };
    return Task;
}());
export { Task };
export var DriverTaskStatus;
(function (DriverTaskStatus) {
    DriverTaskStatus[DriverTaskStatus["notTaken"] = 0] = "notTaken";
    DriverTaskStatus[DriverTaskStatus["taken"] = 1] = "taken";
    DriverTaskStatus[DriverTaskStatus["done"] = 2] = "done";
    DriverTaskStatus[DriverTaskStatus["offered"] = 3] = "offered";
    DriverTaskStatus[DriverTaskStatus["rejected"] = 4] = "rejected";
})(DriverTaskStatus || (DriverTaskStatus = {}));
