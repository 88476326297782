if ($('[data-page=team]').length) {
	require('@chenfengyuan/datepicker');

	// ------------------------------- Modals ---------------------------------------
	let usersMarkedForDeletion = [];
	let teamMarkedForDeletion = 0;
	let clickCount = 0;

	$('.plus-sign').on('click', (e) => openModal($('.team-modal')));

	$('.cc-button').on('click', function () {
		openModal($('.cc-modal'), $(this).data('id'), $(this).data('ficc'));
	});

	$('.team-modal_close, .cc-modal_close').on('click', (e) => closeModal());

	// Initialise editable select
	$('.editable-select').editableSelect();

	// Checks if the user is currently writing in the select box
	$('.editable-select').on('input', function () {
		if ($(this).val().trim() === '') {
			$('.save-ce').removeClass('allowed');
			$('.save-ce').addClass('not-allowed');
		} else {
			$('.save-ce').removeClass('not-allowed');
			$('.save-ce').addClass('allowed');
		}
	});

	// This is needed, if the user chooses an element from the select box without 
	// typing add the allowed class to the element
	EditableSelect.prototype.select = function ($li) {
		if (!this.$list.has($li) || !$li.is('li.es-visible:not([disabled])')) return;
		this.$input.val($li.text());
		if (this.options.filter) this.hide();
		this.filter();
		this.utility.trigger('select', $li);

		$('.save-ce').addClass('allowed');
	};

	// Prevent submit if select is empty and add new preset team by cloning team-box
	$('.add-ce-form').on('submit', function (e) {
		e.preventDefault();
		let currentSelect = $(this).parent().find($('.editable-select'));


		if (currentSelect.val().trim() !== '') {
			$.ajax({
				type: 'POST',
				url: BASE_URL + 'team/createPresetTeam',
				data: {
					dtCE: currentSelect.val()
				},
				dataType: 'json',
				success: (result) => {
					if (result.success) {
						const teamUser = result.teamUser;
						const clone = cloneTeamBox(
							teamUser.fiPresetTeam,
							result.idTeamUser,
							teamUser.dtHandle,
							teamUser.dtStartDate,
							teamUser.dtEndDate);

						closeModal();
						$('.team-container').append(clone);
						$('.plus-sign').removeClass('no-teams');
						$('.plus-sign + p').remove();
					} else {
						$('<p class="form-message error">' + result.message + '</p>').insertBefore(currentSelect);
					}
				},
				error: (error) => {
					console.log(error);
					closeModal();
					VanillaToasts.create({
						text: JSON.parse(error.responseText).message,
						type: 'error',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
				}
			});
		}
	});

	$('.add-cc-form').on('submit', function (e) {
		e.preventDefault();

		const checkedValue = $(this).find('input[type=radio]:checked').val();
		const idPresetTeam = $('input[name="presetId"]').val();

		$.ajax({
			type: 'POST',
			url: BASE_URL + 'team/updatePresetTeamCC',
			data: {
				dtCC: checkedValue,
				presetId: idPresetTeam
			},
			dataType: 'json',
			success: (result) => {

				result.success ? $('.cc-button[data-id="' + $('input[name="presetId"]').val() + '"]').data('ficc', checkedValue) : '';

				VanillaToasts.create({
					text: result.message,
					type: result.success ? 'success' : 'error',
					timeout: 3000,
					icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
				});

				setTimeout(() => { closeModal(); }, 1000);
			},
			error: (error) => {
				console.log(error);
				VanillaToasts.create({
					text: JSON.parse(error.responseText).message,
					type: 'error',
					timeout: 3000,
					icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
				});
			}
		});
	});

	// ------------------------------------------------------------------------------

	// ------------------------------ Add Team member--------------------------------

	// Add new team member container to group

	$('.team-box_addEntry').on('click', function () {
		if ($(this).hasClass('addCE')) {
			addTeamMemberContainer($(this), $(this).data('id'), $(this).data('presetuserid'), 1);
		} else {
			addTeamMemberContainer($(this), $(this).data('id'), $(this).data('presetuserid'), 0);
		}
	});

	$('.team-box').on('click', '.team-box_entry_action', function () {
		if ($(this).hasClass('insert')) {
			let parent = $(this).parent();

			let data = {
				presetTeamId: $(this).data('id'),
				userHandle: parent.children('.worker-select').val(),
				startDate: parent.children('.addStartDate').val(),
				endDate: parent.children('.addEndDate').val() === '' ? '&#8734;' : parent.children('.addEndDate').val(),
				presetTeamUserId: $(this).data('presetuserid'),
			};

			if (parent.hasClass('cancel') && !parent.hasClass('edit-mode')) {
				parent.remove();
			} else if (parent.hasClass('cancel') && parent.hasClass('edit-mode')) {
				// Needed to remove display none from the next element
				parent.next().css('display', 'flex');
				parent.remove();
			} else {
				// Check if is edit-mode or add-mode
				if (parent.hasClass('edit-mode')) {
					$(this).hasClass('isCE') ? data.isCE = 1 : data.isCE = 0;
					teamBoxEntryAjaxCall(parent, BASE_URL + 'team/updatePresetTeamUser', data);
				} else {
					// Check if a new chef d'equipe is added or a normal team member
					if (!$(this).hasClass('isCE')) {
						teamBoxEntryAjaxCall(parent, BASE_URL + 'team/addPresetTeamUser', data);
					} else {
						teamBoxEntryAjaxCall(parent, BASE_URL + 'team/addPresetTeamCE', data);
					}
				}
			}
		} else {
			let presetTeamUserId = $(this).data('presetuserid');
			let presetTeamId = $(this).data('id');
			let parent = $(this).parent();

			$.ajax({
				type: 'POST',
				url: BASE_URL + 'team/removePresetTeamUser',
				data: {
					presetTeamUserId,
					presetTeamId
				},
				dataType: 'json',
				success: (result) => {
					if (result.deletion) {
						parent.remove();
					}

					VanillaToasts.create({
						text: result.message,
						type: result.success ? 'success' : 'error',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
				},
				error: (error) => {
					VanillaToasts.create({
						text: JSON.parse(error.responseText).message,
						type: 'error',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
					console.log(error);
				}
			});
		}
	});

	// -------------------------------------------------------------------------------

	// Remove class cancel if startdate is added else add class
	$('.team-box_entry_startDate.addStartDate').on('input', function () {
		let targetParent = $(this).parent();
		let targetedElement = $(this).next().next().children();

		if ($(this).val().trim() === '') {
			targetedElement.removeClass('fa-check');
			targetedElement.addClass('fa-times');
			targetParent.addClass('cancel');
		} else {
			targetedElement.removeClass('fa-times');
			targetedElement.addClass('fa-check');
			targetParent.removeClass('cancel');
		}
	});

	$('.team-box .license-plate').on('change', function () {
		let teamID = $(this).data('id');
		let licenseText = $(this).val();

		$.ajax({
			type: 'POST',
			url: BASE_URL + 'team/updatePresetTeamCar',
			data: {
				idPresetTeam: teamID,
				dtLicencePlate: licenseText
			},
			dataType: 'json',
			success: (result) => {
				if (result.success) {
					VanillaToasts.create({
						text: result.message,
						type: 'success',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
				} else {
					VanillaToasts.create({
						text: result.message,
						type: 'error',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
				}
			},
			error: (error) => {
				VanillaToasts.create({
					text: JSON.parse(error.responseText).message,
					type: 'error',
					timeout: 3000,
					icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
				});
				console.log(error);
			}
		});
	});

	// Handle the remove actions -> remove team or team member
	$('.team-box_removeTeam').on('click', function () {
		let element = $(this);
		let presetTeamId = element.data('id');

		// Delete mode -> remove team member from the team
		if (element.hasClass('deleteMode')) {
			$.ajax({
				type: 'POST',
				url: BASE_URL + 'team/deletePresetTeamUsers',
				data: {
					idPresetTeam: presetTeamId,
					presetUserId: usersMarkedForDeletion
				},
				dataType: 'json',
				beforeSend: () => {
					return confirm('Do you want to delete the selected team user(s)?');
				},
				success: (result) => {
					// Get the checkbox's team-box_entry parent and remove it
					usersMarkedForDeletion = [];
					clickCount = 0;
					teamMarkedForDeletion = 0;

					result.deletionArray.forEach(idUser => {
						$('input[data-presetUserId="' + idUser + '"]').closest('.team-box_entry').remove();

						VanillaToasts.create({
							text: result.message,
							type: 'success',
							timeout: 3000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});
					});

					if (result.saveArray) {
						result.saveArray.forEach(idUser => {
							VanillaToasts.create({
								text: 'Team Leader could not be deleted, each team needs a leader',
								type: 'error',
								timeout: 3000,
								icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
							});
						});
					}

					$('input[type="checkbox"]').prop("checked", false);
					$('.team-box_removeTeam').removeClass('deleteMode');
				},
				error: (error) => {
					// console.log(error);
					VanillaToasts.create({
						text: JSON.parse(error.responseText).message,
						type: 'error',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
				}
			});
		} else {
			if (confirm('Do you want to delete this preset team?')) {
				$.ajax({
					type: 'POST',
					url: BASE_URL + 'team/deletePresetTeam',
					data: {
						idPresetTeam: presetTeamId,
					},
					dataType: 'json',
					success: (result) => {
						element.closest('.team-box').remove();
						if ($('.team-box').length === 1) {
							$('.plus-sign').addClass('no-teams');
							$('.plus-sign').after('<p>Aucune équipe a été créé!</p>');
						}
						VanillaToasts.create({
							text: result.message,
							type: 'success',
							timeout: 3000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});
					},
					error: (error) => {
						console.log(error);
						VanillaToasts.create({
							text: JSON.parse(error.responseText).message,
							type: 'error',
							timeout: 3000,
							icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
						});
					}
				});
			}
		}
	});

	// Enter edit mode by clicking on a team-box field
	$('.team-box').on('click', '.team-box_entry_name, .team-box_entry_startDate, .team-box_entry_endDate', function (e) {
		let element = $(this).parent();
		if (!element.hasClass('add-mode')) {
			// Check if you are already in edit mode
			if (element.hasClass('edit-mode')) {
				return;
			} else {
				let presetId = element.find($('.team-box_entry_action')).data('id');
				let presetUserId = element.find($('.team-box_entry_action')).data('presetuserid');
				let name = element.find($('.team-box_entry_name')).text();
				let startDate = element.find($('.team-box_entry_startDate')).text();
				let endDate = element.find($('.team-box_entry_endDate')).text();
				let hasCE = element.find($('.team-box_entry_action')).hasClass('isCE');

				let clone = cloneTeamBoxEntry(presetId, 0, 'edit-mode', presetUserId);

				element.css('display', 'none');
				clone.insertBefore(element);

				$('.team-box .team-box_entry').removeClass('template');
				clone.find('.worker-select').editableSelect();
				clone.find('.worker-select').val(name);

				clone.find('[data-toggle="datepicker"]').datepicker({
					format: 'dd.mm.yyyy',
					weekStart: 1
				});

				clone.find('.addStartDate[data-toggle="datepicker"]').val(startDate);
				clone.find('.addEndDate[data-toggle="datepicker"]').val(endDate);
				hasCE ? clone.find($('.team-box_entry_action')).addClass('isCE') : '';
			}
		}
	});

	$('.team-box').on('click', 'input[type="checkbox"]', function () {
		let idPresetUser = $(this).data('presetuserid');
		let currentTeam = $(this).data('id');

		clickCount++;

		if (clickCount === 1) {
			teamMarkedForDeletion = $(this).data('id');
		}

		if (currentTeam === teamMarkedForDeletion) {
			if ($(this).prop('checked')) {
				usersMarkedForDeletion.push(idPresetUser);
			} else {
				usersMarkedForDeletion = usersMarkedForDeletion.filter(function (value, index, arr) {
					return value !== idPresetUser;
				});
			}

			if (usersMarkedForDeletion.length !== 0) {
				$(this).closest('.team-box').find('.team-box_removeTeam').addClass('deleteMode');
			} else {
				$(this).closest('.team-box').find('.team-box_removeTeam').removeClass('deleteMode');
				clickCount = 0;
				teamMarkedForDeletion = 0;
			}
		}
	});

	// ----------------------- functions --------------------------
	function openModal($element, id, fiCC) {
		$('.form-message').remove();

		$('.modal-background').css('display', 'flex');
		$element.css('display', 'block');

		if (id !== undefined) {
			$element.find('input[type="hidden"]').val(id);

			if ($element.find('input[id="radio_' + fiCC + '"').val() == fiCC) {
				$element.find('input[id="radio_' + fiCC + '"').prop('checked', true);
			}

		}
	}

	function closeModal() {
		$('.team-modal, .cc-modal').css('display', 'none');
		$('.modal-background').css('display', 'none');

		$('.editable-select').val('');
	}

	function cloneTeamBox(idPresetTeam, idPresetTeamUser, dtHandle, dtStartDate, dtEndDate) {
		let clone = $('.team-box.template').clone(true);

		clone.find('.cc-button').data('id', idPresetTeam);
		clone.find('label').attr('for', 'removeCheckbox_' + idPresetTeamUser);
		clone.find('input').attr('id', 'removeCheckbox_' + idPresetTeamUser);
		clone.find('input').attr('data-id', idPresetTeam).attr('data-user', dtHandle);

		clone.find('.team-box_entry_name').text(dtHandle);
		clone.find('.team-box_entry_startDate').text(dtStartDate);
		clone.find('.team-box_entry_endDate').html(dtEndDate);

		clone.find('.team-box_entry_action').attr('data-id', idPresetTeam);
		clone.find('.team-box_entry_action').attr('data-presetuserid', idPresetTeamUser);

		clone.find('.team-box_addEntry').attr('data-id', idPresetTeam);
		clone.find('.team-box_entry_removeTeam').attr('data-id', idPresetTeam);

		clone.removeClass('template');

		return clone;
	}

	function cloneTeamBoxEntry(presetId, isCE, mode, presetUserId) {
		// clone template
		let clone = $('.team-box_entry.template').clone(true);

		// Needed because add-mode and edit-mode are different
		clone.addClass(mode);

		if (mode !== 'edit-mode') {
			// Needed for the user to be able to cancel the add operation, update is always executable
			clone.find('.team-box_entry_action.insert > span').removeClass('fa-check').addClass('fa-times');
			clone.addClass('cancel');
		} else {
			clone.find('.team-box_entry_action.insert').attr('data-presetuserid', presetUserId);
		}

		clone.find('.worker-select').attr('placeholder', 'Ouvrier');
		clone.find('.worker-select').attr('autocomplete', 'off');
		clone.find('.team-box_entry_action.insert').attr('data-id', presetId);
		clone.find('.team-box_entry_removeBox label').attr('for', 'removeCheckbox_' + presetUserId);
		clone.find('.team-box_entry_removeBox label input').attr('id', 'removeCheckbox_' + presetUserId);
		clone.find('.team-box_entry_removeBox label input').attr('data-presetuserid', presetUserId);
		clone.find('.team-box_entry_removeBox label input').attr('data-id', presetId);

		if (isCE) {
			// Add isCE to the newly created clone
			clone.find('.team-box_entry_action.insert').addClass('isCE');
		}

		return clone;
	}

	function teamBoxEntryAjaxCall(parent, url, data) {
		$('.form-message').remove();
		$.ajax({
			type: 'POST',
			url: url,
			data: data,
			dataType: 'json',
			success: (result) => {
				if (result.success) {
					if (!result.addedInfo) {
						let clone = $('.team-box_entry.template').clone(true);
						let lowercaseHandle = data.userHandle.toLowerCase();
						let handle = data.userHandle.charAt(0).toUpperCase() + lowercaseHandle.slice(1);

						clone.find('.team-box_entry_removeBox label').attr('for', 'removeCheckbox_' + result.idTeamUser);
						clone.find('.team-box_entry_removeBox label input').attr('id', 'removeCheckbox_' + result.idTeamUser);
						clone.find('.team-box_entry_action, .team-box_entry_removeBox label input').attr('data-id', result.teamUser.fiPresetTeam);
						clone.find('.team-box_entry_action, .team-box_entry_removeBox label input').attr('data-presetuserid', result.idTeamUser);
						clone.find('.worker-select').after(`<div class="team-box_entry_name">${handle}</div>`);
						clone.find('.addStartDate').after(`<div class="team-box_entry_startDate">${data.startDate}</div>`);
						clone.find('.addEndDate').after(`<div class="team-box_entry_endDate">${data.endDate}</div>`);
						clone.find('.worker-select, .addStartDate, .addEndDate').remove();
						clone.find('.team-box_entry_action').removeClass('insert');
						clone.find('.team-box_entry_action span').removeClass('fa-check').addClass('fa-minus');

						if (result.teamUser.dtIsCE == 1) {
							clone.addClass('ce-container');
							clone.find('.team-box_entry_action').addClass('isCE');
						}

						clone.removeClass('template');

						// Add new element after parent, then remove the parent
						parent.after(clone);

						parent.remove();

						if (result.removedEntries) {
							console.log(result.removedEntries);
							result.removedEntries.forEach(id => {
								$(`[data-presetuserid="${id}"]`).first().closest('.team-box_entry').remove();
							});
						}
					}

					VanillaToasts.create({
						text: result.addedInfo ? result.addedInfo : result.message,
						type: 'success',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
				} else {
					parent.remove();
					VanillaToasts.create({
						text: result.message,
						type: 'error',
						timeout: 3000,
						icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
					});
				}
			},
			error: (error) => {
				VanillaToasts.create({
					text: JSON.parse(error.responseText).message,
					type: 'error',
					timeout: 3000,
					icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
				});
			},
		});
	}

	function addTeamMemberContainer($element, presetId, presetUserId, isCE) {
		let clone = cloneTeamBoxEntry(presetId, 0, 'add-mode', presetUserId);

		if (isCE) {
			clone = cloneTeamBoxEntry(presetId, 1, 'add-mode', presetUserId);
			$element.closest('.team-box').find('.ce-container').first().before(clone);
		} else {
			clone.insertBefore($element);
		}

		$element.closest('.team-box').find('.team-box_entry.template').removeClass('template');
		$element.closest('.team-box').find('.worker-select').editableSelect();
		$element.closest('.team-box').find('[data-toggle="datepicker"]').datepicker({
			format: 'dd.mm.yyyy',
			weekStart: 1
		});
	}
}
