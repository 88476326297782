export default class Nav{
	constructor(){
		if ($("nav").length > 0) {
		
			// *********** VARIABLES ******************
		
			const $nav = $("nav");
		
		
			// *********** LISTENERS ******************
		
			$("nav").on("mouseleave", closeNav);
		
			$("nav .toggler").on("click mouseenter", openNav);
		
			// *********** FUNCTIONS ******************
			function closeNav() {
				if($("[data-page='welcome']").length > 0)return;
				$('.logo > img').attr('src', BASE_URL + 'src/assets/images/bettendorf_mobile_login_logo.svg');
		
				setTimeout(() => {
					$nav.removeClass("open blend");
				}, 20);
			}
		
			function openNav() {
				$('.logo > img').attr('src', BASE_URL + 'src/assets/images/bettendorf_logo_white.svg');
		
				setTimeout(() => {
					$nav.addClass("open blend");
				}, 1);
			}
		}
	}
}
$(() => {
	new Nav();
});
