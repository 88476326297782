var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var Moment = require('moment');
import { Task } from './models/task';
import { Driver } from './models/users';
import DropdownMenu from './_dropdownMenu';
import Project from './models/projects';
import { SocketEventHandler } from './models/socketEventHandler';
var Trips = /** @class */ (function () {
    function Trips() {
        var _this = this;
        this._trips = [];
        this.availableTasks = Task.getAvailableTasks();
        if (document.querySelector('.header'))
            this.drivers = Array.from(document.querySelector('.header').querySelectorAll('.handle')).map(function (driver) {
                return new Driver(driver.textContent, parseInt(driver.getAttribute('data-id-user')));
            });
        else
            this.drivers = [];
        document.querySelectorAll('.project').forEach(function (trip) {
            _this._trips.push(new Trip(trip, _this.drivers, _this.availableTasks));
        });
        this.setupHeaderNavListener();
        new SocketEventHandler(this._trips);
    }
    Trips.prototype.setupHeaderNavListener = function () {
        var _this = this;
        // HEADER NAV
        document.querySelector(".shift-forward-day").addEventListener('click', (function (e) {
            _this.shiftDayForward();
        }));
        document.querySelector(".shift-backward-day").addEventListener('click', (function (e) {
            _this.shiftDayBackward();
        }));
        document.querySelector(".shift-backward-week").addEventListener('click', (function (e) {
            _this.shiftWeekBackward();
        }));
        document.querySelector(".shift-forward-week").addEventListener('click', (function (e) {
            _this.shiftWeekForward();
        }));
        document.querySelector(".week .today").addEventListener('click', (function (e) {
            _this.setDateToToday();
        }));
        this.setupWeekSwitcherDatePicker();
    };
    Trips.prototype.shiftDayForward = function () {
        $.post(BASE_URL + "trip/shiftDayForward", (function (site) {
            document.querySelector('.app').innerHTML = site;
            new Trips();
        }));
    };
    Trips.prototype.shiftDayBackward = function () {
        $.post(BASE_URL + "trip/shiftDayBackward", (function (site) {
            document.querySelector('.app').innerHTML = site;
            new Trips();
        }));
    };
    Trips.prototype.shiftWeekForward = function () {
        $.post(BASE_URL + "trip/shiftWeekForward", (function (site) {
            document.querySelector('.app').innerHTML = site;
            new Trips();
        }));
    };
    Trips.prototype.shiftWeekBackward = function () {
        $.post(BASE_URL + "trip/shiftWeekBackward", (function (site) {
            document.querySelector('.app').innerHTML = site;
            new Trips();
        }));
    };
    Trips.prototype.setDateToToday = function () {
        $.post(BASE_URL + "trip/setDateToToday", (function (site) {
            document.querySelector('.app').innerHTML = site;
            new Trips();
        }));
    };
    Trips.prototype.setupWeekSwitcherDatePicker = function () {
        var _this = this;
        var datepickerOptions = {
            format: "dd.mm.yyyy",
            autoHide: true
        };
        var $input = $("[data-toggle=week-date-picker]");
        $input.datepicker(datepickerOptions);
        $input.on("pick.datepicker", function (e) { return __awaiter(_this, void 0, void 0, function () {
            var date, site;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        date = new Moment(e.date).format("YYYY/MM/DD");
                        return [4 /*yield*/, $.post(BASE_URL + "trip/setDate", { date: date })];
                    case 1:
                        site = _a.sent();
                        document.querySelector('.app').innerHTML = site;
                        new Trips();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return Trips;
}());
var Trip = /** @class */ (function () {
    function Trip(trip, availableDrivers, availableTasks) {
        var _this = this;
        this.tripEl = trip;
        this.deleteTripEl = this.tripEl.querySelector('.delete-trip');
        this.idTrip = parseInt(trip.getAttribute('data-id-trip'));
        this.idProject = parseFloat(trip.querySelector('.project-id').textContent);
        this.combinedHandle = trip.querySelector('.project-details .handle').textContent;
        this.constructionSite = {
            address: trip.querySelector('.address').textContent,
            country: trip.querySelector('.country').textContent,
            plz: trip.querySelector('.plz').textContent,
            town: trip.querySelector('.town').textContent
        };
        this.availableTasks = availableTasks;
        this.availableDrivers = availableDrivers;
        // setup tasks
        this.tasks = Array.from(trip.querySelectorAll('.task-row:not(.template)')).map(function (taskEl) {
            return Task.fromElement(taskEl, availableDrivers, _this.availableTasks, _this);
        });
        // setup trip order
        this.tripOrders = Array.from(trip.querySelectorAll('.project-order > div')).map(function (orderContainer) {
            var idUser = parseInt(orderContainer.querySelector('span').getAttribute('data-id-user'));
            var handle = orderContainer.querySelector('span').innerText;
            var order = parseInt(orderContainer.querySelector('input').value);
            var driver = new Driver(handle, idUser);
            return new TripOrder(orderContainer, _this.idTrip, driver, order);
        });
        // setup trip collapse toggle
        this.tripEl.querySelector('.project-details .header').addEventListener('click', function () {
            _this.tripEl.classList.toggle('collapsed');
        });
        this.deleteTripEl.addEventListener('click', function () { _this.deleteTrip(); });
    }
    /**
     * Adds an empty task row
     */
    Trip.prototype.addEmptyTaskRow = function () {
        var taskRow = document.querySelector('.task-row.template').cloneNode(true);
        taskRow.classList.remove('template');
        this.tripEl.querySelector('.tasks').appendChild(taskRow);
        var task = Task.fromElement(taskRow, this.availableDrivers, this.availableTasks, this);
        this.tasks.push(task);
    };
    Trip.prototype.deleteTrip = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, $.post(BASE_URL + "trip/deleteTrip", {
                            idTrip: this.idTrip
                        })];
                    case 1:
                        _a.sent();
                        this.tripEl.remove();
                        return [2 /*return*/];
                }
            });
        });
    };
    return Trip;
}());
export { Trip };
var TripOrder = /** @class */ (function () {
    function TripOrder(orderEl, idTrip, driver, order) {
        this.orderEl = orderEl;
        this.orderInputEl = this.orderEl.querySelector('input');
        this.idTrip = idTrip;
        this.driver = driver;
        this.order = order;
        this.setupOrderListener();
    }
    TripOrder.prototype.setupOrderListener = function () {
        var _this = this;
        this.orderInputEl.addEventListener('blur', function () { _this.updateOrder(); });
    };
    TripOrder.prototype.updateOrder = function () {
        this.order = this.orderInputEl.value !== '' ? parseInt(this.orderInputEl.value) : null;
        $.post(BASE_URL + "trip/updateOrder", {
            idTrip: this.idTrip,
            idDriver: this.driver.idUser,
            order: this.order
        });
    };
    return TripOrder;
}());
var AddTripModal = /** @class */ (function () {
    function AddTripModal() {
        var _this = this;
        this.modalEl = document.querySelector('.add-project-modal');
        this.addTripButton = document.querySelector('.add-project');
        this.inputEl = this.modalEl.querySelector('input');
        this.saveEl = this.modalEl.querySelector('.add');
        this.cancelEl = this.modalEl.querySelector('.cancel');
        this.addTripButton.addEventListener('click', function () {
            _this.modalEl.classList.add('open');
            console.log('pressed');
        });
        this.cancelEl.addEventListener('click', function (e) {
            _this.inputEl.value = '';
            _this.toggle();
        });
        this.saveEl.addEventListener('click', function (e) {
            if (!_this.selectedProject)
                return;
            _this.addProject();
            _this.selectedProject = undefined;
            _this.inputEl.value = '';
            _this.toggle();
        });
        this.setupDropDown();
    }
    AddTripModal.prototype.setupDropDown = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dd, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = DropdownMenu;
                        _b = {
                            entrySearchInput: this.inputEl,
                            placeResultElAfter: null,
                            nameOfIdField: 'idProject',
                            nameOfValueField: 'value'
                        };
                        return [4 /*yield*/, Project.getProjects()];
                    case 1:
                        dd = _a.apply(void 0, [(_b.availableEntries = _c.sent(),
                                _b)]);
                        this.inputEl.addEventListener('entrySelected', function (e) {
                            _this.selectedProject = e.detail.selectedEntry;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AddTripModal.prototype.toggle = function () {
        this.modalEl.classList.toggle('open');
    };
    AddTripModal.prototype.addProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, $.post(BASE_URL + "trip/addTrip", {
                            idProject: this.selectedProject.idProject
                        })];
                    case 1:
                        _a.sent();
                        location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AddTripModal;
}());
if (document.querySelector('[data-page="trip"]')) {
    document.body.onload = function () {
        require('@chenfengyuan/datepicker');
        new Trips();
        new AddTripModal();
    };
}
