export var SelectionComponent = {
    props: {
        assets: Array
    },
    data: function () {
        return {
            imageWidth: null,
            allSelected: false
        };
    },
    methods: {
        calculateImageSize: function () {
            if (!this.$refs.image)
                return;
            var firstImage = this.$refs.image[0];
            this.imageWidth = firstImage.height * (4 / 3);
        },
        previewImageStyles: function (asset, index) {
            if (!this.$refs.image)
                return;
            var imageEl = this.$refs.image[index];
            var styles = {
                width: null,
                height: null,
                transform: null,
            };
            if (asset.rotate % 4 === 1 || asset.rotate % 4 === 3) {
                var width = imageEl.clientWidth;
                var height = imageEl.clientHeight;
                styles.width = height + 'px';
            }
            styles.transform = "rotate(" + asset.rotate * 90 + "deg)";
            return styles;
        },
        toggleSelectAll: function () {
            var _this = this;
            this.allSelected = !this.allSelected;
            this.assets.forEach(function (asset) { return asset.isSelected = _this.allSelected; });
        },
        toggleAssetSelection: function (asset) {
            asset.isSelected = !asset.isSelected;
            this.$emit('on-asset-selection-click');
        }
    },
    computed: {},
    template: "\n        <div class='second-col'>\n            <div class='selection-row'>\n                <span>S\u00E9lection</span>\n                <button\n                    class='select-all-button'\n                    @click='toggleSelectAll'>S\u00E9lectionner tout</button>\n            </div>\n            <div\n                class='assets'\n                :style=\"{'grid-auto-columns': imageWidth + 'px'}\">\n                <div\n                    v-for='(asset, index) in assets'\n                    @click='toggleAssetSelection(asset)'\n                    :class=\"['image-container', {selected: asset.isSelected, delete: asset.toDelete}]\">\n                    <img\n                        :src='asset.assetPath'\n                        :style='previewImageStyles(asset, index)'\n                        ref='image'>\n                </div>\n            </div>\n        </div>",
    created: function () {
        // for(let i = 0; i < 20; i++)
        //     this.assets.push('https://placeimg.com/250/250/any?t=' + i);
    },
    mounted: function () {
        this.calculateImageSize();
        window.addEventListener('resize', this.calculateImageSize);
    }
};
