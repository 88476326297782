import { DriverTaskStatus } from "./task";
var warningShown = false;
var SocketEventHandler = /** @class */ (function () {
    function SocketEventHandler(trips) {
        var _this = this;
        this.trips = trips;
        try {
            this.websocket = new WebSocket("ws" + (IS_DEV ? '' : 's') + "://" + SOCKET_SERVER_URL + (IS_DEV ? ':3000' : ''));
            this.websocket.onopen = function () { return _this.onOpen(); };
            //@ts-ignore
            this.websocket.onmessage = function (ev) { return _this.onMessage(ev.data); };
            this.websocket.onerror = function () {
                if (!warningShown) {
                    VanillaToasts.create({
                        text: "Livesync feature not available",
                        type: 'error',
                        timeout: 3000,
                        icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
                    });
                    warningShown = true;
                }
            };
        }
        catch (e) {
            if (!warningShown) {
                VanillaToasts.create({
                    text: "Livesync feature not available",
                    type: 'error',
                    timeout: 3000,
                    icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
                });
                warningShown = true;
            }
        }
    }
    SocketEventHandler.prototype.onOpen = function () {
        this.setHandle();
    };
    SocketEventHandler.prototype.onMessage = function (data) {
        var result = JSON.parse(data);
        if (result.event !== 'setTripTaskUserstatus')
            return;
        var eventData = result.data;
        var idTripTask = eventData.idTripTask, idDriver = eventData.idDriver, status = eventData.status;
        this.setTripTaskUserstatus(idTripTask, idDriver, status);
    };
    SocketEventHandler.prototype.setHandle = function () {
        var data = {
            namespace: 'all',
            event: 'setHandle',
            data: {
                namespace: 'drivers',
                handle: USER_HANDLE
            }
        };
        this.websocket.send(JSON.stringify(data));
    };
    SocketEventHandler.prototype.setTripTaskUserstatus = function (idTripTask, idDriver, status) {
        var found = false;
        var i = 0;
        // loop through all trips
        while (!found && i < this.trips.length) {
            var trip = this.trips[i];
            var j = 0;
            // loop through all tasks of trip
            while (!found && j < trip.tasks.length) {
                var task = trip.tasks[j];
                if (task.idTripTask === idTripTask) {
                    found = true;
                    // find driver
                    var driver = task.drivers.find(function (driver) { return driver.idUser === idDriver; });
                    // update status for driver
                    switch (status) {
                        case 'not taken':
                            driver.status = DriverTaskStatus.notTaken;
                            break;
                        case 'taken':
                            driver.status = DriverTaskStatus.taken;
                            break;
                        case 'done':
                            driver.status = DriverTaskStatus.done;
                            break;
                        case 'offered':
                            driver.status = DriverTaskStatus.offered;
                            break;
                        case 'rejected':
                            driver.status = DriverTaskStatus.rejected;
                            break;
                    }
                }
                j++;
            }
            i++;
        }
    };
    return SocketEventHandler;
}());
export { SocketEventHandler };
