export var ValidatedSliderComponent = {
    components: {},
    props: {
        validatedAssets: Array,
    },
    data: function () {
        return {};
    },
    computed: {},
    methods: {
        previewImageStyles: function (asset, index) {
            if (!this.$refs.image)
                return;
            var imageEl = this.$refs.image[index];
            var styles = {
                width: null,
                height: null,
                transform: null,
            };
            if (asset.rotate % 4 === 1 || asset.rotate % 4 === 3) {
                var width = imageEl.clientWidth;
                var height = imageEl.clientHeight;
                styles.width = height + 'px';
            }
            styles.transform = "rotate(" + asset.rotate * 90 + "deg)";
            return styles;
        },
        toggleAssetSelection: function (asset) {
            asset.isSelected = !asset.isSelected;
            this.$emit('on-validated-asset-click');
        }
    },
    template: "\n        <div class='validated-slider'>\n            <div\n                v-if='validatedAssets.length === 0'\n                class='image-container placeholder'>\n                <img src=''>\n            </div>\n            <div\n                v-for='(asset, index) in validatedAssets'\n                class='image-container'\n                :class='{selected: asset.isSelected}'>\n                <img\n                :src='asset.assetPath'\n                :style='previewImageStyles(asset, index)'\n                @click='toggleAssetSelection(asset)'\n                ref='image'>\n            </div>\n        </div>\n    ",
    created: function () {
    }
};
