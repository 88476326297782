import _dropdownMenu from "../_dropdownMenu";
import { EventBus } from "./pictureValidator";
export var MoveProjectAssetComponent = {
    props: {
        availableProjects: Array,
    },
    data: function () {
        return {
            input: null,
            selectedProjectId: null,
        };
    },
    methods: {
        cancel: function () {
            this.$emit('on-cancel-move');
        },
        confirm: function () {
            EventBus.$emit('on-move-selected-assets-to-project', this.selectedProjectId);
        }
    },
    template: "\n    <div class='move-asset-container'>\n        <span>S\u00E9lectionnez un projet</span>\n        <div class='input-container'>\n            <input\n                placeholder='Projet...'\n                ref='input'>\n            <i class='fas fa-chevron-down down-icon'></i>\n        </div>\n        <div class='actions'>\n            <button\n                class='cancel-button'\n                @click='cancel'>Annuler</button>\n            <button\n                class='confirm-button'\n                @click='confirm'>D\u00E9placer</button>\n        </div>\n    </div>\n    ",
    mounted: function () {
        var _this = this;
        this.input = this.$refs.input;
        _dropdownMenu({
            entrySearchInput: this.input,
            availableEntries: this.availableProjects,
            nameOfIdField: 'idProject',
            nameOfValueField: 'handle',
            placeResultElAfter: null
        });
        this.input.addEventListener('entrySelected', function (e) {
            _this.selectedProjectId = parseFloat(e.detail.selectedEntry.idProject);
        });
    }
};
