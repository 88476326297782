var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ToolbarComponent } from './ToolbarComponent';
import { ProjectComponent } from './project';
var Project = /** @class */ (function () {
    function Project(idProject, handle, assets) {
        this.isCollapsed = false;
        this.idProject = idProject;
        this.handle = handle;
        this.assets = assets;
        this.isCollapsed = this.assets.length === 0;
    }
    Project.prototype.fetchHandle = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, $.post(BASE_URL + 'pictureValidator/getHandle', {
                                idProject: this.idProject
                            })];
                    case 1:
                        _a.handle = _b.sent();
                        this.assets.forEach(function (asset) { return (asset.idProject = _this.idProject); });
                        return [2 /*return*/];
                }
            });
        });
    };
    Project.prototype.deleteAsset = function (asset) {
        this.assets = this.assets.filter(function (el) { return el.idAsset !== asset.idAsset; });
    };
    return Project;
}());
export { Project };
var Asset = /** @class */ (function () {
    function Asset(idAsset, idProject, assetPath, tags, handle, date) {
        this.isSelected = false;
        this.needsUpload = false;
        this.toDelete = false;
        this.rotate = 0;
        this.isValidated = false;
        this.idAsset = idAsset;
        this.idProject = idProject;
        this.assetPath = assetPath;
        this.tags = tags;
        this.handle = handle;
        this.date = date;
    }
    Object.defineProperty(Asset.prototype, "assetPath", {
        get: function () {
            return this._assetPath;
        },
        set: function (value) {
            this._assetPath = value;
        },
        enumerable: true,
        configurable: true
    });
    return Asset;
}());
export { Asset };
var Tag = /** @class */ (function () {
    function Tag(idTag, name) {
        this.idTag = idTag;
        this.name = name;
    }
    return Tag;
}());
export { Tag };
export var EventBus = new Vue();
if (document.querySelector("[data-page='pictureValidator']")) {
    var app = new Vue({
        el: '.app',
        components: {
            'toolbar-component': ToolbarComponent,
            'project-component': ProjectComponent
        },
        data: {
            allProjectsCollapsed: true,
            allAssetsSelected: false,
            projects: [],
            availableProjects: [],
            availableTags: [],
        },
        methods: {
            toggleAllProjectsCollapsed: function () {
                var _this = this;
                this.allProjectsCollapsed = !this.allProjectsCollapsed;
                this.projects.forEach(function (project) { return project.isCollapsed = _this.allProjectsCollapsed; });
            },
            fetchProjects: function () {
                return __awaiter(this, void 0, void 0, function () {
                    var projects, _a, _b;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _b = (_a = JSON).parse;
                                return [4 /*yield*/, $.post(BASE_URL + 'pictureValidator/getProjects')];
                            case 1:
                                projects = _b.apply(_a, [_c.sent()]);
                                this.projects = projects.map(function (project) {
                                    return new Project(parseFloat(project.idProject), project.combinedHandle, project.assets.map(function (asset) {
                                        return new Asset(parseInt(asset.idAsset), parseFloat(asset.fiProject), BASE_URL + 'src/assets/uploads/pictures/' + asset.dtFilename, asset.tags.map(function (tag) {
                                            return new Tag(parseInt(tag.fiTag), tag.name);
                                        }), asset.handle, new Date(asset.dtCreateTimestamp));
                                    }));
                                });
                                return [2 /*return*/];
                        }
                    });
                });
            },
            fetchAvailableProjects: function () {
                return __awaiter(this, void 0, void 0, function () {
                    var projects, _a, _b;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _b = (_a = JSON).parse;
                                return [4 /*yield*/, $.post(BASE_URL + 'api/mobile/getActiveProjects')];
                            case 1:
                                projects = _b.apply(_a, [_c.sent()]);
                                this.availableProjects = projects.map(function (project) {
                                    return new Project(parseFloat(project.idProject), project.idProject + ' - ' + project.combinedHandle, []);
                                });
                                return [2 /*return*/];
                        }
                    });
                });
            },
            fetchAvailableTags: function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, _b, _c;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                _a = this;
                                _c = (_b = JSON).parse;
                                return [4 /*yield*/, $.post(BASE_URL + 'pictureValidator/getTagSuggestions')];
                            case 1:
                                _a.availableTags = _c.apply(_b, [_d.sent()]).map(function (tag) {
                                    return new Tag(parseInt(tag.idTag), tag.dtName);
                                });
                                return [2 /*return*/];
                        }
                    });
                });
            },
            moveSelectedAssetsToProject: function (idProject) {
                return __awaiter(this, void 0, void 0, function () {
                    var selectedAssets;
                    var _this = this;
                    return __generator(this, function (_a) {
                        selectedAssets = this.projects.map(function (project) { return __awaiter(_this, void 0, void 0, function () {
                            var selectedProjectAssets, existingProject, createdProject;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (project.idProject === idProject)
                                            return [2 /*return*/];
                                        selectedProjectAssets = project.assets.filter(function (asset) { return asset.isSelected; });
                                        // remove selected assets from project
                                        project.assets = project.assets.filter(function (asset) { return !asset.isSelected; });
                                        existingProject = this.projects.find(function (p) { return p.idProject === idProject; });
                                        if (!existingProject) return [3 /*break*/, 1];
                                        existingProject.assets = existingProject.assets.concat(selectedProjectAssets);
                                        return [3 /*break*/, 3];
                                    case 1:
                                        createdProject = new Project(idProject, null, selectedProjectAssets);
                                        return [4 /*yield*/, createdProject.fetchHandle()];
                                    case 2:
                                        _a.sent();
                                        this.projects.push(createdProject);
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                    });
                });
            },
            toggleSelectAllAssets: function () {
                var _this = this;
                this.allAssetsSelected = !this.allAssetsSelected;
                this.projects.forEach(function (project) { return project.assets.forEach(function (asset) { return asset.isSelected = _this.allAssetsSelected; }); });
            },
            exportAssets: function () {
                var _this = this;
                var validatedAssets = [];
                this.projects.forEach(function (project) {
                    validatedAssets = validatedAssets.concat(project.assets.filter(function (asset) { return asset.isValidated && !asset.needsUpload; }));
                });
                var newPictures = [];
                this.projects.forEach(function (project) {
                    newPictures = newPictures.concat(project.assets.filter(function (asset) { return asset.needsUpload; }));
                });
                var toDeletePictures = [];
                this.projects.forEach(function (project) {
                    toDeletePictures = toDeletePictures.concat(project.assets.filter(function (asset) { return asset.toDelete; }));
                });
                var formData = new FormData();
                newPictures.forEach(function (picture) {
                    var pictureData = {
                        idProject: picture.idProject,
                        tags: picture.tags.map(function (tag) { return tag.idTag; }),
                        rotate: picture.rotate % 4
                    };
                    formData.append('pictureFiles[]', picture.file);
                    formData.append('pictureData[]', JSON.stringify(pictureData));
                });
                validatedAssets.forEach(function (picture) {
                    var pictureData = {
                        tags: picture.tags.map(function (tag) { return tag.idTag; }),
                        idAsset: picture.idAsset.toString(),
                        idProject: picture.idProject.toString(),
                        rotate: picture.rotate % 4
                    };
                    formData.append('validatedPictures[]', JSON.stringify(pictureData));
                });
                toDeletePictures.forEach(function (picture) {
                    var pictureData = {
                        idAsset: picture.idAsset.toString(),
                    };
                    formData.append('picturesToDelete[]', JSON.stringify(pictureData));
                });
                $.ajax({
                    type: 'POST',
                    url: BASE_URL + "/pictureValidator/uploadPictures",
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function () {
                        _this.removeAssets(__spreadArrays(validatedAssets, newPictures, toDeletePictures));
                        EventBus.$emit('on-export-done');
                    },
                    error: function () { return EventBus.$emit('on-export-done'); }
                });
            },
            addProject: function () {
                var project = new Project(null, null, []);
                project.isCollapsed = false;
                this.projects.unshift(project);
            },
            removeAssets: function (assets) {
                var _this = this;
                assets.forEach(function (asset) {
                    var idProject = asset.idProject;
                    var project = _this.projects.find(function (project) { return project.idProject === idProject; });
                    project.deleteAsset(asset);
                });
                // remove projects with empty assets
                this.projects = this.projects.filter(function (project) { return project.assets.length > 0; });
            },
            unselectOtherProjectsAssets: function (thisProject) {
                this.projects.filter(function (project) { return project !== thisProject; }).
                    forEach(function (project) { return project.assets.forEach(function (asset) { return asset.isSelected = false; }); });
            }
        },
        computed: {
            canExport: function () {
                var hasProjects = this.projects.length > 0;
                var projectsHaveIds = !this.projects.some(function (project) { return !project.idProject; });
                var projectWithValidatedAssets = this.projects.some(function (project) { return project.assets.some(function (asset) { return asset.isValidated; }); });
                var projectWithDeletedAssets = this.projects.some(function (project) { return project.assets.some(function (asset) { return asset.toDelete; }); });
                return hasProjects && projectsHaveIds && (projectWithValidatedAssets || projectWithDeletedAssets);
            }
        },
        created: function () {
            this.fetchProjects();
            this.fetchAvailableProjects();
            this.fetchAvailableTags();
            EventBus.$on('on-move-selected-assets-to-project', this.moveSelectedAssetsToProject);
            EventBus.$on('on-export', this.exportAssets);
        }
    });
}
