export var AssetPreviewComponent = {
    props: {
        selectedAssets: Array,
        previewMode: Boolean
    },
    data: function () {
        return {
            previewImageEl: null
        };
    },
    methods: {
        validateSelectedAssets: function () {
            this.selectedAssets.forEach(function (asset) {
                asset.isValidated = true;
                asset.isSelected = false;
            });
        },
        unvalidateSelectedAssets: function () {
            this.selectedAssets.forEach(function (asset) {
                asset.isValidated = false;
                asset.isSelected = false;
            });
        },
        markDeleteSelectedAssets: function () {
            this.selectedAssets.forEach(function (asset) {
                asset.toDelete = true;
                asset.isValidated = false;
                asset.isSelected = false;
            });
        },
        undoDeleteSelectedAssets: function () {
            this.selectedAssets.forEach(function (asset) {
                asset.toDelete = false;
            });
        },
        rotateLeft: function () {
            this.selectedAssets.forEach(function (asset) {
                if (asset.rotate >= 1)
                    asset.rotate--;
                else
                    asset.rotate = 3;
            });
        },
        rotateRight: function () {
            this.selectedAssets.forEach(function (asset) {
                asset.rotate++;
            });
        },
    },
    computed: {
        restoreOnly: function () {
            return !this.selectedAssets.some(function (asset) { return !asset.toDelete; });
        },
        unvalidateOnly: function () {
            return !this.selectedAssets.some(function (asset) { return !asset.isValidated && !asset.needsUpload; });
        },
        noNewAssetsSelected: function () {
            return !this.selectedAssets.some(function (asset) { return asset.needsUpload; });
        },
        previewImageStyles: function () {
            if (!this.previewImageEl)
                return;
            var image = this.selectedAssets[0];
            var imageEl = this.previewImageEl;
            var styles = {
                width: null,
                height: null,
                transform: null,
            };
            if (image.rotate % 4 === 1 || image.rotate % 4 === 3) {
                var width = imageEl.clientWidth;
                var height = imageEl.clientHeight;
                styles.width = height + 'px';
            }
            styles.transform = "rotate(" + image.rotate * 90 + "deg)";
            return styles;
        }
    },
    mounted: function () {
        this.previewImageEl = this.$refs.previewImage;
    },
    template: "\n        <div class='preview-container' :style=\"{height: !previewMode ? 'auto' : ''}\">\n            <a :href='selectedAssets[0].assetPath' target=\"_blank\">\n                <img\n                    v-if='selectedAssets.length === 1 && previewMode'\n                    :src='selectedAssets[0].assetPath'\n                    :style='previewImageStyles'\n                    class='preview-image'\n                    alt='preview image'\n                    ref='previewImage'\n                    >\n            </a>\n            <div class='actions'>\n                <button\n                    v-if='!restoreOnly'\n                    class='delete-button'\n                    :class='{disabled: !previewMode}'\n                    @click='markDeleteSelectedAssets'>Supprimer</button>\n                <button\n                    v-else\n                    class='restore-button'\n                    :class='{disabled: !previewMode}'\n                    @click='undoDeleteSelectedAssets'>R\u00E9tablir</button>\n\n                <button\n                    class='rotate-button'\n                    :class='{disabled: !previewMode}'\n                    @click='rotateLeft'><img src='/src/assets/icons/rotateLeft.svg'></button>\n                    <button\n                    class='rotate-button'\n                    :class='{disabled: !previewMode}'\n                    @click='rotateRight'><img src='/src/assets/icons/rotateRight.svg'></button>\n                    \n                    <button\n                    v-if='!unvalidateOnly'\n                    class='validate-button'\n                    :class='{disabled: !previewMode}'\n                    @click='validateSelectedAssets'>Valider</button>\n                <button\n                    v-else-if='noNewAssetsSelected'\n                    class='unvalidate-button'\n                    @click='unvalidateSelectedAssets'>D\u00E9valider</button>\n            </div>\n        </div>\n    "
};
