var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-ignore
import VanillaToasts from 'vanillatoasts';
var NoteModal = /** @class */ (function () {
    function NoteModal(noteEl, projectTimeEntry, workers, note) {
        var _this = this;
        if (note === void 0) { note = ''; }
        this.projectTimeEntry = projectTimeEntry;
        this.workers = workers;
        // sidebar infos
        this.dateEl = document.querySelector('.notes-modal .date');
        this.userEl = document.querySelector('.notes-modal .user');
        this.ceEl = document.querySelector('.notes-modal .ce');
        this.projectEl = document.querySelector('.notes-modal .project');
        this.noteEl = noteEl;
        this.textarea = document.querySelector('.notes-modal .note');
        this.abort = document.querySelector('.notes-modal .abort');
        this.save = document.querySelector('.notes-modal .save');
        // close modal
        this.abort.addEventListener('click', function () { document.querySelector('.notes-modal').classList.add('hide'); });
        this.save.addEventListener('click', function () { _this.saveNote(); });
        this.populateInformation();
    }
    /**
     * populates sidebar and note
     */
    NoteModal.prototype.populateInformation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var date, formattedDate, userHandle, ceHandle, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        date = new Date(this.projectTimeEntry.date);
                        formattedDate = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
                        return [4 /*yield*/, this.workers.find(function (worker) { return worker.idUser === _this.projectTimeEntry.idUser; }).dtHandle];
                    case 1:
                        userHandle = _b.sent();
                        return [4 /*yield*/, this.workers.find(function (worker) { return worker.idUser === _this.projectTimeEntry.idCE; }).dtHandle];
                    case 2:
                        ceHandle = _b.sent();
                        // sidebar
                        this.dateEl.textContent = formattedDate;
                        this.userEl.textContent = userHandle;
                        this.ceEl.textContent = ceHandle;
                        this.projectEl.textContent = this.projectTimeEntry.idProject + '';
                        // note
                        _a = this.textarea;
                        return [4 /*yield*/, this.getNoteForEntry(this.projectTimeEntry)];
                    case 3:
                        // note
                        _a.textContent = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NoteModal.prototype.saveNote = function () {
        return __awaiter(this, void 0, void 0, function () {
            var note, result, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        note = this.textarea.value;
                        _b = (_a = JSON).parse;
                        return [4 /*yield*/, $.post(BASE_URL + "timetracker/saveNote", {
                                date: this.projectTimeEntry.date,
                                idUser: this.projectTimeEntry.idUser,
                                idCE: this.projectTimeEntry.idCE,
                                idProject: this.projectTimeEntry.idProject,
                                note: note
                            })];
                    case 1:
                        result = _b.apply(_a, [_c.sent()]);
                        if (result.success) {
                            VanillaToasts.create({
                                text: result.message,
                                type: 'success',
                                timeout: 3000,
                                icon: BASE_URL + "src/assets/images/bettendorf_mobile_login_logo.svg"
                            });
                        }
                        switch (result.action) {
                            case 'insert':
                            case 'update': {
                                this.noteEl.classList.add('active');
                                break;
                            }
                            case 'delete': {
                                this.noteEl.classList.remove('active');
                                break;
                            }
                            default:
                                break;
                        }
                        this.closeModal();
                        return [2 /*return*/];
                }
            });
        });
    };
    NoteModal.prototype.getNoteForEntry = function (projectTimeEntry) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, $.post(BASE_URL + "timetracker/getNoteForEntry", {
                            date: projectTimeEntry.date,
                            idUser: projectTimeEntry.idUser,
                            idCE: projectTimeEntry.idCE,
                            idProject: projectTimeEntry.idProject,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    NoteModal.prototype.closeModal = function () {
        document.querySelector('.notes-modal').classList.add('hide');
    };
    return NoteModal;
}());
export { NoteModal };
