/**
 * An observer that can be used to bind a callback function to a subject
 */
var Observer = /** @class */ (function () {
    /**
     *
     * @param key The key of the observer (to differentiate observers, bind to a certain property)
     * @param notify The callback function to execute when a value changes
     */
    function Observer(key, notify) {
        this.key = key;
        this.notify = notify;
    }
    return Observer;
}());
export { Observer };
