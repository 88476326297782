var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Tag as ImportedTag } from '../pictureValidator/pictureValidator';
var Tag = /** @class */ (function (_super) {
    __extends(Tag, _super);
    function Tag(idTag, name) {
        var _this = _super.call(this, idTag, name) || this;
        _this.isSelected = false;
        return _this;
    }
    return Tag;
}(ImportedTag));
export var TagsComponent = {
    template: '#tags-template',
    data: function () {
        return {
            tags: [],
            newTag: '',
            selectedTag: new Tag(null, null),
            showAddTagInput: false,
            searchTerm: ''
        };
    },
    computed: {
        isAddButtonDisabled: function () {
            var _this = this;
            return this.newTag === '' || this.tags.some(function (tag) { return tag.name === _this.newTag; });
        },
        isDeleteButtonEnabled: function () {
            return this.tags.some(function (tag) { return tag.isSelected; });
        },
        filteredTags: function () {
            var _this = this;
            if (!this.searchTerm.trim())
                return this.tags;
            return this.tags.filter(function (tag) {
                return tag.name.toLowerCase().includes(_this.searchTerm.toLowerCase());
            });
        }
    },
    methods: {
        fetchTags: function () {
            return __awaiter(this, void 0, void 0, function () {
                var result, _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _b = (_a = JSON).parse;
                            return [4 /*yield*/, $.post(BASE_URL + 'template/getTags')];
                        case 1:
                            result = _b.apply(_a, [_c.sent()]);
                            this.tags = result.map(function (tag) { return new Tag(tag.idTag, tag.dtName); });
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteSelectedTags: function () {
            var tagsToDelete = [];
            this.tags.forEach(function (tag, index, arr) {
                if (tag.isSelected) {
                    tagsToDelete.push(tag.idTag);
                }
            });
            tagsToDelete.forEach(function (idTag) { return $.post(BASE_URL + 'template/deleteTag', { idTag: idTag }); });
            this.tags = this.tags.filter(function (tag) { return !tag.isSelected; });
        },
        selectTag: function (tag) {
            this.selectedTag.isSelected = false;
            this.selectedTag = tag;
            tag.isSelected = true;
        },
        showInput: function () {
            var _this = this;
            this.showAddTagInput = true;
            this.newTag = '';
            setTimeout(function () {
                var input = _this.$refs.addTagInput;
                input.focus();
                input.addEventListener('animationend', function () { return input.classList.remove('shake'); });
            }, 10);
        },
        addTag: function () {
            return __awaiter(this, void 0, void 0, function () {
                var input, result;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.newTag.trim() === '' || this.tags.some(function (tag) { return tag.name === _this.newTag; })) {
                                input = this.$refs.addTagInput;
                                input.classList.add('shake');
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, $.post(BASE_URL + 'template/addTag', { name: this.newTag })];
                        case 1:
                            result = _a.sent();
                            if (result === 'false')
                                return [2 /*return*/];
                            this.tags.unshift(new Tag(result, this.newTag));
                            this.newTag = '';
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteTag: function (tagToDelete) {
            return __awaiter(this, void 0, void 0, function () {
                var result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!confirm('Supprimer ce tag?'))
                                return [2 /*return*/];
                            return [4 /*yield*/, $.post(BASE_URL + 'template/deleteTag', { idTag: tagToDelete.idTag })];
                        case 1:
                            result = _a.sent();
                            if (result !== 'false') {
                                this.tags = this.tags.filter(function (tag) { return tag !== tagToDelete; });
                                this.selectedTag = new Tag(null, null);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateTag: function (tagToUpdate, e) {
            return __awaiter(this, void 0, void 0, function () {
                var newName, updatedTagExists, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            newName = e.target.value;
                            updatedTagExists = this.tags.some(function (tag) { return tag.name.toLowerCase() === newName; });
                            if (newName === tagToUpdate.name || updatedTagExists) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, $.post(BASE_URL + 'template/updateTag', { idTag: tagToUpdate.idTag, name: newName })];
                        case 1:
                            result = _a.sent();
                            tagToUpdate.name = newName;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    created: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchTags()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
};
