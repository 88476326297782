var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var Task = /** @class */ (function () {
    function Task(idTask, name) {
        this.isSelected = false;
        this.isEditMode = false;
        this.idTask = idTask;
        this.name = name;
    }
    return Task;
}());
export var TasksComponent = {
    template: '#tasks-template',
    data: function () {
        return {
            tasks: [],
            newTask: '',
            selectedTask: new Task(null, null),
            showAddTaskInput: false,
            searchTerm: ''
        };
    },
    computed: {
        isAddButtonDisabled: function () {
            var _this = this;
            return this.newTask === '' || this.tasks.some(function (task) { return task.name === _this.newTask; });
        },
        isDeleteButtonEnabled: function () {
            return this.tasks.some(function (task) { return task.isSelected; });
        },
        filteredTasks: function () {
            var _this = this;
            if (!this.searchTerm.trim())
                return this.tasks;
            return this.tasks.filter(function (task) {
                return task.name.toLowerCase().includes(_this.searchTerm.toLowerCase());
            });
        }
    },
    methods: {
        fetchTasks: function () {
            return __awaiter(this, void 0, void 0, function () {
                var result, _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _b = (_a = JSON).parse;
                            return [4 /*yield*/, $.post(BASE_URL + 'template/getTasks')];
                        case 1:
                            result = _b.apply(_a, [_c.sent()]);
                            this.tasks = result.map(function (task) { return new Task(task.idPredefinedTask, task.dtLabel); });
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteSelectedTasks: function () {
            var tasksToDelete = [];
            this.tasks.forEach(function (task, index, arr) {
                if (task.isSelected) {
                    tasksToDelete.push(task.idTask);
                }
            });
            tasksToDelete.forEach(function (idTask) { return $.post(BASE_URL + 'template/deleteTask', { idTask: idTask }); });
            this.tasks = this.tasks.filter(function (task) { return !task.isSelected; });
        },
        selectTask: function (task) {
            this.selectedTask.isSelected = false;
            this.selectedTask = task;
            task.isSelected = true;
        },
        showInput: function () {
            var _this = this;
            this.showAddTaskInput = true;
            this.newTask = '';
            setTimeout(function () {
                var input = _this.$refs.addTaskInput;
                input.focus();
                input.addEventListener('animationend', function () { return input.classList.remove('shake'); });
            }, 10);
        },
        addTask: function () {
            return __awaiter(this, void 0, void 0, function () {
                var input, result;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.newTask.trim() === '' || this.tasks.some(function (task) { return task.name === _this.newTask; })) {
                                input = this.$refs.addTaskInput;
                                input.classList.add('shake');
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, $.post(BASE_URL + 'template/addTask', { name: this.newTask })];
                        case 1:
                            result = _a.sent();
                            if (result === 'false')
                                return [2 /*return*/];
                            this.tasks.unshift(new Task(result, this.newTask));
                            this.newTask = '';
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteTask: function (taskToDelete) {
            return __awaiter(this, void 0, void 0, function () {
                var result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!confirm('Supprimer ce task?'))
                                return [2 /*return*/];
                            return [4 /*yield*/, $.post(BASE_URL + 'template/deleteTask', { idTask: taskToDelete.idTask })];
                        case 1:
                            result = _a.sent();
                            if (result !== 'false') {
                                this.tasks = this.tasks.filter(function (task) { return task !== taskToDelete; });
                                this.selectedTask = new Task(null, null);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        updateTask: function (taskToUpdate, e) {
            return __awaiter(this, void 0, void 0, function () {
                var newName, updatedTaskExists, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            newName = e.target.value;
                            updatedTaskExists = this.tasks.some(function (task) { return task.name.toLowerCase() === newName; });
                            if (newName === taskToUpdate.name || updatedTaskExists) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, $.post(BASE_URL + 'template/updateTask', { idTask: taskToUpdate.idTask, name: newName })];
                        case 1:
                            result = _a.sent();
                            taskToUpdate.name = newName;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    created: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchTasks()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
};
