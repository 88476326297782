import { Project, EventBus } from "./pictureValidator";
import { TopProjectComponent } from "./TopProjectComponent";
import { ValidatedSliderComponent } from "./ValidatedSliderComponent";
export var ProjectComponent = {
    components: {
        'top-project-component': TopProjectComponent,
        'validated-slider-component': ValidatedSliderComponent,
    },
    template: "\n        <div \n        :class=\"['project', {\n            collapsed: project.isCollapsed\n        }]\">\n            <top-project-component\n                :project='project'\n                :available-projects='availableProjects'\n                :available-tags='availableTags'\n                v-on:on-unselect-other-projects-assets=\"$emit('on-unselect-other-projects-assets')\"\n                ></top-project-component>\n            <validated-slider-component\n                :validatedAssets='validatedPictures'\n                v-on:on-validated-asset-click='validatedAssetClick'></validated-slider-component>\n        </div>\n    ",
    props: {
        project: Project,
        availableProjects: Array,
        availableTags: Array,
    },
    data: function () {
        return {};
    },
    computed: {
        validatedPictures: function () {
            return this.project.assets.filter(function (asset) { return asset.isValidated; });
        }
    },
    methods: {
        validatedAssetClick: function () {
            this.project.assets.forEach(function (asset) {
                if (!asset.isValidated)
                    asset.isSelected = false;
            });
            this.$emit('on-unselect-other-projects-assets', this.project);
        }
    },
    created: function () {
        var _this = this;
        EventBus.$on('toggle-project-collapse', function () {
            _this.project.isCollapsed = !_this.project.isCollapsed;
        });
    }
};
