import Moment from 'moment';
import { extendMoment } from './moment-range';
import Dropdown from './_dropdownMenu';

const moment = extendMoment(Moment);



$(() => {
	if ($("[data-page='planner/planner']").length) {

		// ********** VARIABLES *************

		const MODE = {
			"overview": "overview",
			"user_selected": "user_selected",
			"project_selected": "project_selected",
			"project_add": "project_add",
			"multi_select": "multi_select",
			"user_status": "user_status",
			"confirm": "confirm"
		};

		const SELECT_MODE = {
			"normal": "normal",
			"special": "special",
			"delete": "delete",
			"move": "move"
		};

		const USER_STATUS_MODE = {
			"planner": "planner",
			"modal": "modal"
		};

		const STATUS_CLASSES = {
			Maladie: "sick",
			Absent: "absent",
			Exceptionnel: "exceptionnel",
			Formation: "formation",
			"Non justifiée": "unjustified",
			Récupération: "recuperation"
		};


		let CURRENT_SELECT_MODE = null;
		let CURRENT_MODE = MODE.overview;

		let SELECTED_USER = null;

		let SELECTED_ID_ENTRY = null;
		let SELECTED_ID_PROJECT = null;
		let SELECTED_TEAM = null;

		let SELECTED_USER_STATUS = null;

		let drakeEnabled = false;

		// COPY
		let projectSelected = false;
		let selectedEntries = [];

		let $app = $(".app .main");

		let $inspector = $(".inspector");
		let $notesInspector = $(".notes-inspector");
		let $contextInfoMultiSelect = $(".context-info.multiselect");
		let $contextInfoUserStatus = $(".context-info.user-status");
		let $userStatusModal = $(".user-status-modal");

		let drake;
		// ********** LISTENERS *************

		setupListeners();


		// ************ SETUP ***************

		// ********** FUNCTIONS *************

		function addRowForTeam() {

			if ($(this).hasClass("disabled")) return;

			let $days = $(this).prev().find(".day");
			let $day = $days.first();
			let $lastEntry = $day.children().last();


			let highestPosition = parseInt($lastEntry.attr("data-position"));

			let $template = $(".planner-entry.template");


			$days.each((_, day) => {
				let $entry = $template.clone();
				let $day = $(day);

				$entry.removeClass("template");
				$entry.attr("data-position", highestPosition + 1);

				$day.append($entry);

			});
			checkAddRowButton.call(this);

			setMode(MODE.overview);

			setupListeners();

		}

		function checkAddRowButton() {
			// check if last row is empty
			let lastItemsEmpty = $(this).prev().find(".day").has(":last-child.empty").length === 6;

			if (lastItemsEmpty) {
				$(this).addClass("disabled");
			}

			else {
				$(this).removeClass("disabled");
			}
		}

		function shiftDayForward() {

			$.post(`${BASE_URL}planner/shiftDayForward`, null, applyView)

		}
		function shiftDayBackward() {

			$.post(`${BASE_URL}planner/shiftDayBackward`, null, applyView)

		}
		function shiftWeekForward() {

			$.post(`${BASE_URL}planner/shiftWeekForward`, null, applyView)

		}
		function shiftWeekBackward() {

			$.post(`${BASE_URL}planner/shiftWeekBackward`, null, applyView)

		}

		function setDateToToday(){
			$.post(`${BASE_URL}planner/setDateToToday`, null, applyView)
		}

		function applyView(view) {
			$app.html(view);
			setup();
		}

		async function selectUser(e, idUser) {
			// console.log('select user');
			$(".status-chooser").removeClass("hide");
			// in case of reload
			if (idUser) {
				$(`.entry[data-id-user=${idUser}]`).addClass("selected");

				switch (CURRENT_MODE) {
					case MODE.user_selected:
						await setAffectedProjectsForUser();
						$(".planner-entries").addClass("user-selected");
						break;

					default:
						break;
				}
				await fetchAndApplyUserStates();
			}
			else {

				if (CURRENT_MODE !== MODE.overview && CURRENT_MODE !== MODE.user_selected)
					return;

				// deselect if clicked entry is selected and go to overview mode
				if ($(this).hasClass("selected")) {
					$(".app .project-selector").show();
					setMode(MODE.overview);
				}
				else {
					$(".app .project-selector").hide();
					undoUserSelectedMode();
					// set selected user and mode
					SELECTED_USER = $(this).data("id-user");
					$(`.entry[data-id-user=${SELECTED_USER}]`).addClass("selected");

					// set selected team
					SELECTED_TEAM = $(this).closest(".team").data("id-team");

					$(".planner-entries").addClass("user-selected");
					setMode(MODE.user_selected);
					setAffectedProjectsForUser();
					fetchAndApplyUserStates();
				}
			}
		}

		async function fetchUserStatesForCurrentDateRange(idUser) {
			return JSON.parse(await $.post(`${BASE_URL}planner/getUserStatesForDateRangeAndUser`, { idUser }))
		}
		async function fetchUserStatesForUpcomingDates(idUser) {
			return JSON.parse(await $.post(`${BASE_URL}planner/getUserStatesForUpcomingDatesAndUser`, { idUser }))
		}


		async function setAffectedProjectsForUser() {

			// $(".planner-entry.highlighted").attr("class", "planner-entry");
			// $(".date-labels li").attr("class", "");

			if (CURRENT_MODE !== MODE.user_selected && CURRENT_MODE !== MODE.user_status) {
				return;
			}

			let result = await $.post(`${BASE_URL}planner/getAffectedEntriesForUser`, { idUser: SELECTED_USER });
			result = JSON.parse(result);

			let affectedEntries = result.affectedEntries;

			affectedEntries.forEach((entry) => {
				let $cell = $(`[data-id-entry=${entry.idDayProjectTeam}]`);

				$(`[data-id-entry=${entry.idDayProjectTeam}]`).addClass("highlighted");


				// mark cell if user is removed for it
				if (entry.removed === "1")
					$cell.addClass("removed ");

			});
		}

		function setupSelectedUserStatusEntries(statusEntries) {
			// console.log('setup status days');

			selectedEntries = [];

			statusEntries.forEach(entry => {
				selectedEntries.push({
					idUserStatus: entry.idUserStatus,
					startDate: entry.dtStartDate,
					endDate: entry.dtEndDate,
					status: entry.dtStatus,
					formattedStatus: entry.formattedStatus,
				});
			});
		}


		function applySelectedUserStatusEntriesStyle() {
			// console.log('apply user status styles');
			let $sundayEl = $(".date-labels .sunday");
			let $todayEl = $(".date-labels .today");
			//remove old states
			$(`.date-labels li`).removeClass();
			$sundayEl.addClass("sunday");
			$todayEl.addClass("today");
			let currentDateRange = [];

			selectedEntries.forEach(statusEntry => {

				if (statusEntry.toDelete)
					return;
				// status during daterange
				if (statusEntry.endDate) {
					let dateRange = moment.range(statusEntry.startDate, statusEntry.endDate);
					let intersectDays = getIntersectionDates(dateRange);
					if (statusEntry.startDate === statusEntry.endDate)
						intersectDays.push(statusEntry.startDate);

					intersectDays.forEach(date => {
						let dateStatusObj = {
							status: statusEntry.status,
							classStatus: statusEntry.formattedStatus,
							date
						}

						currentDateRange.push(dateStatusObj);
					})
				}
				else {
					let dateStatusObj = {
						status: statusEntry.status,
						classStatus: statusEntry.formattedStatus,
						date: statusEntry.startDate
					}

					currentDateRange.push(dateStatusObj);
				}
			});


			currentDateRange.forEach((currentDateEntry) => {

				let $dateLabel = $(`.date-labels li[data-date='${currentDateEntry.date}']`);


				$dateLabel.addClass(currentDateEntry.classStatus);
			});
		}

		async function fetchAndApplyUserStates() {

			let fetchedEntries = await fetchStatusDaysForUser(SELECTED_USER) || [];

			setupSelectedUserStatusEntries(fetchedEntries)
			applySelectedUserStatusEntriesStyle();
		}

		let selectedProject = null;

		/**
		 *
		 * @param {HTMLElement} plannerEntryEl
		 */
		async function displayProjectDropdown(plannerEntryEl) {

			let $this = $(plannerEntryEl);

			// disallow going to add project mode when NOT in overview mode
			if (CURRENT_MODE !== MODE.overview || !$this.hasClass("empty"))
				return;

			// prevent adding dropdown on cell with dropdown present or dropdown still active
			if ($this.hasClass("edit") || CURRENT_MODE === MODE.project_add)
				return;
			// set mode
			CURRENT_MODE = MODE.project_add;



			// fetch dropdownmenu
			let $dropdown = $(await ($.post(`${BASE_URL}planner/getAddPlannerEntryDropdown`)));

			// bind listeners
			$dropdown.find(".cancel-button").click(cancelAddEntry);
			$dropdown.find(".save-button").click(savePlannerEntry.bind($dropdown));


			// hiding plus icon
			$this.children().addClass("hide");

			// setting the edit class
			$this.addClass("edit");

			// attaching the dropdown to the planner entry
			$this.append($dropdown);



			let entrySearchInput = document.getElementsByClassName("entry-search-input")[0];
			let projects = null;
			let availableProjects = JSON.parse(await getEntries("POST", `${BASE_URL}planner/getAvailableProjects`));

			availableProjects = availableProjects.map(project => {
				if(project.fiCustomer === project.fiReferencedCustomer){
					project.referencedCustomerHandle = ''
				}

				project.value = project.idProject + ` <br><span class="client-handle">(${project.customerHandle} ${project.referencedCustomerHandle !== '' ? '-' + project.referencedCustomerHandle : '' })</span>`;
				return project;
			});


			let dropdown = Dropdown({
				availableEntries: availableProjects,
				entrySearchInput,
				nameOfIdField: 'idProject',
				nameOfValueField: 'value',
			});


			entrySearchInput.addEventListener("entrySelected", function (e) {
				selectedProject = e.detail.selectedEntry;

				// enable save button
				$this.find(".save-button").removeClass("disabled");
				entrySearchInput.value = selectedProject.idProject;

			})

			entrySearchInput.addEventListener("keyup", e => {
				if(e.key === "Enter"){
					selectedProject = availableProjects.find(project => project.idProject === entrySearchInput.value);
					savePlannerEntry.call($dropdown);
				}
			});

			entrySearchInput.addEventListener("entrySelected", function (e) {
				selectedProject = e.detail.selectedEntry;

				// enable save button
				$this.find(".save-button").removeClass("disabled");
			});

			entrySearchInput.focus();



			async function getEntries(method, url, data) {
				let ajaxCall = $.ajax(url, {
					method,
					data
				});
				projects = JSON.parse(await ajaxCall);

				// display only active projects
				projects = projects.filter(el => el.dtStatus === "commande en cours");

				return ajaxCall;
			}
		}

		async function savePlannerEntry() {
			let $this = $(this);

			let position = $this.parent().data("position");
			let date = $this.parent().parent().data("date");
			let idPresetTeam = $this.closest(".team").data("id-team");
			let idProject = $this.find(".entry-search-input").val();
			let customerHandle = selectedProject.customerHandle;
			let referencedCustomerHandle = selectedProject.referencedCustomerHandle;

			let result = await $.post(`${BASE_URL}planner/addPlannerEntry`, {
				date,
				idProject,
				idPresetTeam,
				position
			});
			result = JSON.parse(result);

			cancelAddEntry();

			let entry = {
				date,
				position,
				idProject,
				idEntry: result.idDayProjectTeam,
				idPresetTeam,
				customerHandle,
				referencedCustomerHandle
			}

			fillPlannerEntry(entry);
		}

		function fillPlannerEntry(entry) {
			// get the corresponding planner cell
			let $cell = $(`.team[data-id-team=${entry.idPresetTeam}] .day[data-date=${entry.date}] [data-position=${entry.position}]`);


			$cell.removeClass("empty");
			$cell.attr("data-id-entry", entry.idEntry);
			$cell.html(`<span class='project-label'>
							<span>${entry.idProject}</span>
							<span class="client-handle">${entry.customerHandle}${entry.referencedCustomerHandle !== '' ? ' - ' + entry.referencedCustomerHandle : ''}</span>
						</span>`);

			checkAddRowButton.call($cell.parent().parent().next());

		}

		function cancelAddEntry(e) {

			let $entry = $(this).closest(".planner-entry");

			// remove dropdown
			$(".dropdown-container").remove();

			// restore cell cell
			$entry.removeClass("edit");
			$entry.children().removeClass("hide");

			// set mode after timeout (to prevent open dropdown event)
			setTimeout(() => {
				setMode(MODE.overview);
			}, 5);
		}

		async function openInspector(idEntry) {

			// only open in overview mode or project mode
			if (CURRENT_MODE !== MODE.overview && CURRENT_MODE !== MODE.project_selected)
				return;

			// set project and mode
			SELECTED_ID_ENTRY = idEntry
			setTimeout(() => {
				setMode(MODE.project_selected);
			}, 5);


			$inspector.removeClass("closed");

			let entryInfos = await fetchEntryInfos(SELECTED_ID_ENTRY);
			if (entryInfos.success) {
				populateInspector(entryInfos);
			}


		}

		function closeInspector(e) {

			if (CURRENT_MODE === MODE.project_selected) {

				// check if clicked inside of the inspector
				if ($(e.target).closest(".inspector").length !== 0)
					return;

				// set project and mode
				setTimeout(() => {
					setMode(MODE.overview);

				}, 5);

				$inspector.addClass("closed");
			}
		}

		async function fetchEntryInfos(idDayProjectTeam) {
			return JSON.parse(await $.post(`${BASE_URL}planner/getEntryDetails`, { idDayProjectTeam }));
		}

		function populateInspector(entry) {


			let $referencedCustomerHandle = $inspector.find(".handle");
			let $projectTitle = $inspector.find(".title");
			let $projectAddress = $inspector.find(".project__details .address");
			let $projectZip = $inspector.find(".project__details .zip");


			let $customerName = $inspector.find(".name");
			let $customerAddress = $inspector.find(".client__details .address");
			let $customerZip = $inspector.find(".client__details .zip");

			let $ce = $inspector.find(".ce");
			let $teamMembers = $inspector.find(".team-members");
			let $borrowedUsers = $inspector.find(".borrowed-users ul");

			if (!entry.success) return;

			let project = entry.project;
			let customer = entry.customer;
			let referencedCustomer = entry.referencedCustomer;
			let ce = entry.team.ce;
			let workers = entry.team.workers;
			let borrowedWorkers = entry.team.borrowedWorkers;


			$inspector.find(".project").show();
			$inspector.find(".borrowed-users").show();


			$projectTitle.text("Project: " + project.idProject || "");

			if (referencedCustomer) {
				$referencedCustomerHandle.text(referencedCustomer.dtHandle);
				$projectAddress.text(referencedCustomer.dtAddress ? referencedCustomer.dtAddress : "");
				$projectZip.text((referencedCustomer.dtZip ? referencedCustomer.dtZip : "") + " " + referencedCustomer.dtLocality ? referencedCustomer.dtLocality : "");
			}
			else{
				$inspector.find(".project").hide();
			}
			$customerName.text(`${customer.dtFirstName ? customer.dtFirstName : ""} ${customer.dtLastName ? customer.dtLastName : ""}`);
			$customerAddress.text(customer.dtAddress ? customer.dtAddress : "");
			$customerZip.text(`${customer.dtZip ? customer.dtZip : ""} ${customer.dtLocality ? customer.dtLocality : ""}`);

			$ce.text(ce.dtHandle);

			// remove previous entries
			$(".inspector .team-member").remove();

			$(".inspector .borrowed-user").remove();


			// append preset team members
			workers.forEach((worker) => {
				let $worker = $(".team-member.template").clone();

				$worker.removeClass("template");

				if (worker.status)
					$worker.addClass(worker.status);
				if (worker.isRemoved)
					$worker.addClass("removed");

				$worker.attr("data-id-user", worker.idUser);
				$worker.find(".handle").text(worker.dtHandle);

				$teamMembers.append($worker);
			});

			// append borrowed team members
			borrowedWorkers.forEach((worker) => {

				let $worker = $(".borrowed-user.template").clone();

				$worker.removeClass("template");
				$worker.attr("data-id-user", worker.idUser);
				$worker.find(".handle").text(worker.dtHandle);

				// set status if available
				if (worker.status !== null) {

					$worker.addClass(worker.status[0].dtStatus);
				}

				$borrowedUsers.append($worker);
			});

			// populate worker list for borrowing
			populateBorrowDataList();

			// set notes

			$inspector.find(".notes textarea").val(entry.note);
		}

		async function populateUserStatusModal(entries) {

			let $appendAfter = $userStatusModal.find(".template");

			// empty fields
			$userStatusModal.find(".template").nextAll().remove();
			$userStatusModal.find(".handle-label").text(await getHandleByIdUser(SELECTED_USER));

			selectedEntries = [];
			entries.forEach((entry, i) => {
				let $template = $userStatusModal.find(".template").clone();
				let datepickerOptions = {
					format: "dd.mm.yyyy",
					autoHide: true,
					weekStart: 1
					// trigger: $startInput.next()
				}
				$template.removeClass("template");


				let $startInput = $template.find(".start-date-input");
				let $endInput = $template.find(".end-date-input");

				// set values for input fields
				$startInput.val(moment(entry.dtStartDate).format("DD.MM.YYYY"));
				if (entry.dtEndDate)
					$endInput.val(moment(entry.dtEndDate).format("DD.MM.YYYY"));
				$template.find(`option:contains(${entry.dtStatus})`).attr("selected", "selected");

				// attach datepicker to datefields
				$startInput.datepicker(datepickerOptions);
				$endInput.datepicker(datepickerOptions);

				// add changelisteners to date fields
				$startInput.change(function () {
					if (this.value === "")
						return;

					selectedEntries[i].startDate = moment(this.value, "DD.MM.YYYY").format("YYYY-MM-DD");
					selectedEntries[i].toUpdate = true;
				});
				$endInput.change(function () {
					if (this.value === "") {
						selectedEntries[i].endDate = null;
						selectedEntries[i].toUpdate = true;
						return;
					}
					selectedEntries[i].endDate = moment(this.value, "DD.MM.YYYY").format("YYYY-MM-DD");
					selectedEntries[i].toUpdate = true;
				});

				// add changelistener to statuschooser
				$template.find("select").change(function () {
					selectedEntries[i].status = this.options[this.selectedIndex].text;
					selectedEntries[i].toUpdate = true;
				})

				//remove entry listener
				$template.find(".delete").click(function () {
					if (entry.toAdd)
						selectedEntries.splice(i, 1);

					else
						selectedEntries[i].toDelete = true;

					$template.remove();
				});

				$appendAfter.after($template);

				selectedEntries.push({
					idUserStatus: entry.idUserStatus,
					startDate: entry.dtStartDate,
					endDate: entry.dtEndDate,
					status: entry.dtStatus,
				})

			});
		}

		function getIntersectionDates(givenDateRange) {

			let startDate = moment(START_DATE);
			let endDate = moment(END_DATE);

			let viewDateRange = moment.range(startDate, endDate);

			// return;
			let dates = viewDateRange.intersect(givenDateRange);
			if (dates) {
				dates = Array.from(dates.by("day")).map(date => date.format("YYYY-MM-DD"));
			}
			else
				dates = [];

			return dates;


		}

		function addUserStatusRow() {
			if ($userStatusModal.find(".template").next().find(".start-date-input").val() === "")
				return;

			let $template = $userStatusModal.find(".template").clone().removeClass("template");

			let $startInput = $template.find(".start-date-input");
			let $endInput = $template.find(".end-date-input");
			let $select = $template.find("select");

			let datepickerOptions = {
				format: "dd.mm.yyyy",
				autoHide: true,
				weekStart: 1
			}
			// attach datepicker to datefields
			$startInput.datepicker(datepickerOptions);
			$endInput.datepicker(datepickerOptions);

			// add changelisteners to date fields
			$startInput.change(function () {
				selectedEntries[selectedEntries.length - 1].startDate = moment(this.value, "DD.MM.YYYY").format("YYYY-MM-DD");
				selectedEntries[selectedEntries.length - 1].toAdd = true;
			});
			$endInput.change(function () {
				selectedEntries[selectedEntries.length - 1].endDate = moment(this.value, "DD.MM.YYYY").format("YYYY-MM-DD");
			});

			// add changelistener to statuschooser
			$select.change(function () {
				selectedEntries[selectedEntries.length - 1].status = this.options[this.selectedIndex].text;
			})

			//remove entry listener
			$template.find(".delete").click(function () {
				selectedEntries.splice(selectedEntries.length - 1, 1);
				$template.remove();
			});
			$userStatusModal.find(".template").after(
				$template
			);

			selectedEntries.push({
				status: $select[0].options[$select[0].selectedIndex].text
			});
		}

		async function removeTeamUser(idUser, idDayProjectTeam) {

			let result = JSON.parse(await $.post(`${BASE_URL}planner/removeTeamUser`, {
				idUser, idDayProjectTeam
			}));


			if (result.success !== 1)
				return;

			let selectedTeam = getSelectedTeam();

			// mark user as removed in inspector and teamlist
			$inspector.find(`[data-id-user=${idUser}]`).addClass("removed");
			$(`[data-id-team=${selectedTeam}]`).find(`.team-details [data-id-user=${idUser}]`).addClass("removed");

			// mark planner entry for removed user
			if (CURRENT_MODE === MODE.user_selected) {
				getSelectedEntry().addClass("removed");
			}


		}

		async function addTeamUser(idUser, idDayProjectTeam) {

			let result = JSON.parse(await $.post(`${BASE_URL}planner/addTeamUser`, {
				idUser, idDayProjectTeam
			}));


			if (result.success !== 1)
				return;

			// reload UI
			let view = await $.post(`${BASE_URL}planner/refreshApp`);
			applyView(view);

			if (CURRENT_MODE === MODE.project_selected)
				openInspector(SELECTED_ID_ENTRY);
		}

		async function removeBorrowedUser(idUser, idDayProjectTeam) {


			let result = JSON.parse(await $.post(`${BASE_URL}planner/removeBorrowedUser`, { idUser, idDayProjectTeam }));

			if (!result.success)
				return;


			// refresh UI
			let view = await $.post(`${BASE_URL}planner/refreshApp`);
			applyView(view);
			openInspector(SELECTED_ID_ENTRY);

		}

		async function borrowUser(idUser, idDayProjectTeam) {

			if (!idUser || !idDayProjectTeam)
				return;

			let result = JSON.parse(await $.post(`${BASE_URL}planner/borrowUser`, {
				idUser, idDayProjectTeam
			}));
			if (!result.success)
				return;

			let $plannerEntry = $(`[data-id-entry=${idDayProjectTeam}]`);
			let $currentTeam = $plannerEntry.closest(".team")
			let handle = await getHandleByIdUser(idUser);

			// add user to the inspector list
			// get the template
			let $template = $(".borrowed-user.template").clone();
			$template.removeClass("template");

			// set status if available
			if (result.status !== null)
				$template.addClass(result.status[0].dtStatus);

			$template.attr("data-id-user", result.idUser).find(".handle").text(handle);
			$(".borrowed-users ul").append($template);


			// add user to the team list if not present
			if (!$currentTeam.find($(`.team-details [data-id-user=${result.idUser}]`)).length) {
				$template = $(".entry.borrowed.template").clone().
					removeClass("template").
					attr("data-id-user", result.idUser);

				$template.find(".worker-label").text(handle);


				// set status if available
				if (result.status !== null)
					$template.addClass(result.status[0].dtStatus);


				$plannerEntry.closest(".team").find(".team-details").append($template);

			}

			// clear input field
			$(".borrow-user input").val("");

			selectUser(null, SELECTED_USER);

			// remove user in datalist
			$inspector.find(`option:contains(${handle})`).remove();

		}

		window.debugInfo = () => {
			console.dir("current mode: " + CURRENT_MODE);
			console.dir("current select mode: " + CURRENT_SELECT_MODE);
			console.dir("current user: " + SELECTED_USER);
			console.dir("current entryid: " + SELECTED_ID_ENTRY);
			console.dir("current projectid: " + SELECTED_ID_PROJECT);
			console.dir("current team: " + SELECTED_TEAM);
			console.dir("projectToCopySelected: " + projectSelected);
			console.dir("current user status: " + SELECTED_USER_STATUS);
			console.table(selectedEntries);

		}
		async function populateBorrowDataList() {
			let $datalist = $("datalist#workers select");

			let date = getSelectedDate();
			let idPresetTeam = getSelectedTeam();

			// empty datalist
			$datalist.children().not(":first").remove();

			// fetch workers
			let result = JSON.parse(await $.post(`${BASE_URL}planner/getAvailableWorkersForDateAndTeam`, {
				date,
				idPresetTeam,
				idDayProjectTeam: SELECTED_ID_ENTRY
			}));
			if (!result.success)
				return;

			let workers = result.users;


			workers.forEach(el => {

				let $entry = $(`<option >${el.dtHandle}</option>`);

				$datalist.append($entry);

			});
		}

		function plannerEntryHandler(e) {

			if (CURRENT_MODE !== MODE.multi_select) {
				SELECTED_ID_ENTRY = $(this).data("id-entry");
			}


			switch (CURRENT_MODE) {

				case MODE.overview:
					displayProjectDropdown(this);
					openInspector(SELECTED_ID_ENTRY);
					selectedEntries = [];

				case MODE.user_status:
					userStatusHandler(this);
					break;

				case MODE.user_selected:
					togglePlannerEntry();
					break;

				case MODE.multi_select:
					projectSelectedHandler(this);
					break;

				case MODE.confirm:
					projectConfirmHandler(this);
					break;

				default:
					SELECTED_ID_ENTRY = null;
					break;
			}

		}

		function projectSelectedHandler(el) {
			let $el = $(el);

			switch (CURRENT_SELECT_MODE) {
				case SELECT_MODE.normal: case SELECT_MODE.special: {
					if (!projectSelected) {

						setStartingEntry($el.data("id-entry"));
					}
					if (!SELECTED_ID_ENTRY)
						return;

					if (!projectSelected)
						setStartingEntry(SELECTED_ID_ENTRY);

					if (!$el.hasClass("empty"))
						return;

					// clicked on empty cell, add a temporary entry to selected entries list
					else {
						let date = $el.parent().data("date");
						let position = $el.data("position");
						let idPresetTeam = $el.closest(".team").data("id-team");



						let tempEntry = createCopy(date, position, idPresetTeam);

						// check if entry is already present in array
						let foundEntry = selectedEntries.find(entry => {
							return entry.date === date && entry.position === position && entry.idPresetTeam === idPresetTeam;
						});

						if (!foundEntry) {

							let idProject = SELECTED_ID_PROJECT;



							tempEntry.idProject = idProject;
							selectedEntries.unshift(tempEntry);

							applySelection();
						}

						// remove the selection from the entry
						else {

							$el.removeClass("preselected");
							$el.find("i").show();
							$el.find("span").html("");

							selectedEntries = selectedEntries.filter(entry => {
								return entry.date !== foundEntry.date || entry.position !== foundEntry.position;
							});
						}

						updateContextEntriesMultiSelect();

					}
					break;
				}
				case SELECT_MODE.special: {
					break;
				}
				case SELECT_MODE.delete: {

					if ($el.hasClass("empty"))
						return;

					let date = $el.parent().data("date");
					let position = $el.data("position");
					let idPresetTeam = $el.closest(".team").data("id-team");
					let idProject = $el.find(".project-label").text();
					let idDayProjectTeam = $el.data("id-entry");

					if ($el.hasClass("preselected")) {

						let tempEntry = {
							idProject,
							date,
							position,
							idPresetTeam,
							idDayProjectTeam
						}

						selectedEntries.unshift(tempEntry);
						$el.removeClass("preselected");
						$el.addClass("to-be-removed");

						updateContextEntriesMultiSelect();
					}
					else if ($el.hasClass("to-be-removed")) {
						$el.removeClass("to-be-removed");
						$el.addClass("preselected");


						selectedEntries = selectedEntries.filter(entry => {
							return !(entry.idDayProjectTeam === idDayProjectTeam);
						});

						updateContextEntriesMultiSelect();
						// applySelection();
					}
					break;
				}

				case SELECT_MODE.move: {
					let entryIsEmpty = $el.hasClass("empty");
					if (entryIsEmpty && !projectSelected)
						return;

					if (!projectSelected && !entryIsEmpty) {
						setStartingEntry($el.data("id-entry"));
						applySelection();
					}
					else if (projectSelected && entryIsEmpty) {
						let date = $el.parent().data("date");
						let position = $el.data("position");
						let idPresetTeam = $el.closest(".team").data("id-team");
						let idProject = SELECTED_ID_PROJECT;
						let tempEntry = {
							idProject,
							date,
							position,
							idPresetTeam,
						};
						selectedEntries = [tempEntry];
						updateContextEntriesMultiSelect();
						applySelection();
					}
				}
			}

		}

		async function projectConfirmHandler(el) {

			let $cell = $(el);

			if ($cell.hasClass("empty"))
				return;

			// send id of the entry
			let response = JSON.parse(await $.post(`${BASE_URL}planner/projectConfirmHandler`, {
				"idDayProjectTeam": SELECTED_ID_ENTRY
			}));
			if (response.color) {
				$cell.css({ background: `repeating-linear-gradient(-55deg, white 0px, white 2px, ${response.color} 2px, ${response.color} 6px)` });
				$cell.addClass('confirmed')
			}
			else{
				$cell.css({ background: "" });
				$cell.removeClass('confirmed');
			}

		}

		function openContextInfoMultiSelect() {
			let idProject = getSelectedEntry(SELECTED_ID_ENTRY).text();


			$contextInfoMultiSelect.removeClass("closed");
			switch (CURRENT_SELECT_MODE) {
				case SELECT_MODE.normal: {
					$contextInfoMultiSelect.find(".mode").text(`${CURRENT_SELECT_MODE} copy mode`);
					$contextInfoMultiSelect.find(".project-to-copy-label").text(idProject);

					$contextInfoMultiSelect.find(".copy").removeClass("hide");
					$contextInfoMultiSelect.find(".delete").addClass("hide");
					$contextInfoMultiSelect.find(".move").addClass("hide");

					break;
				}

				case SELECT_MODE.special: {
					$contextInfoMultiSelect.find(".mode").text(`${CURRENT_SELECT_MODE} copy mode`);
					// @todo
					break;
				}

				case SELECT_MODE.delete: {
					$contextInfoMultiSelect.find(".mode").text(`${CURRENT_SELECT_MODE} mode`);
					$contextInfoMultiSelect.find(".copy").addClass("hide");
					$contextInfoMultiSelect.find(".move").addClass("hide");
					$contextInfoMultiSelect.find(".delete").removeClass("hide");
					break;
				}

				case SELECT_MODE.move: {
					$contextInfoMultiSelect.find(".project-to-move-label").text(idProject);

					$contextInfoMultiSelect.find(".copy").addClass("hide");
					$contextInfoMultiSelect.find(".move").removeClass("hide");
					$contextInfoMultiSelect.find(".delete").addClass("hide");
					break;
				}
			}

		}

		function openContextInfoUserStatus() {
			$contextInfoUserStatus.removeClass("closed");
			if(getSelectedUserEntry().hasClass('ce'))
				$contextInfoUserStatus.find('.info').show();
			else{
				$contextInfoUserStatus.find('.info').hide();
			}
		}

		function closeContextInfoMultiSelect() {

			$contextInfoMultiSelect.addClass("closed");
			$(".to-be-removed").removeClass("to-be-removed");
			selectedEntries = [];
			updateContextEntriesMultiSelect();
		}
		function closeContextInfoUserStatus() {

			$contextInfoUserStatus.addClass("closed");
			selectedEntries = [];
			updateContextEntriesUserStatus();
		}

		function updateContextEntriesMultiSelect() {

			// remove old entries
			let $oldEntries = $contextInfoMultiSelect.find("tr:not(.template)");
			$oldEntries.nextAll().remove();


			switch (CURRENT_SELECT_MODE) {
				case SELECT_MODE.normal: case SELECT_MODE.special: {

					$contextInfoMultiSelect.find(".copy").removeClass("hide");
					$contextInfoMultiSelect.find(".delete").addClass("hide");
					$contextInfoMultiSelect.find(".move").addClass("hide");

					let $appendToEl = $contextInfoMultiSelect.find("tr").eq(1);




					selectedEntries.forEach(entry => {

						let $template = $(".context-info .copy .template").clone();
						let ceHandle = $(`[data-id-team=${entry.idPresetTeam}]`).find(".ce .worker-label").text();

						// format date
						let tmpDate = new Date(entry.date);
						let date = `${tmpDate.getDate()}.${tmpDate.getMonth() + 1}.${tmpDate.getFullYear()}`;

						$template.removeClass("template");

						$template.find("td").first().text(date);
						$template.find("td").eq(1).text(entry.position)
						$template.find("td").last().text(ceHandle);

						$appendToEl.after($template);
					});

					break;
				}

				case SELECT_MODE.special: {
					// @todo
					break;
				}

				case SELECT_MODE.delete: {
					$contextInfoMultiSelect.find(".copy").addClass("hide");
					$contextInfoMultiSelect.find(".delete").removeClass("hide");

					let $appendToEl = $contextInfoMultiSelect.find(".delete tr").eq(1);

					selectedEntries.forEach(entry => {

						let $template = $(".context-info .delete .template").clone();
						let ceHandle = $(`[data-id-team=${entry.idPresetTeam}]`).find(".ce .worker-label").text();

						// format date
						let tmpDate = new Date(entry.date);
						let date = `${tmpDate.getDate()}.${tmpDate.getMonth() + 1}.${tmpDate.getFullYear()}`;

						$template.removeClass("template");

						$template.find("td").first().text(entry.idProject);
						$template.find("td").eq(1).text(date)
						$template.find("td").eq(2).text(entry.position)
						$template.find("td").last().text(ceHandle);

						$appendToEl.after($template);
					});

					break;
				}

				case SELECT_MODE.move: {
					if (selectedEntries.length === 0)
						return;

					let $appendToEl = $contextInfoMultiSelect.find(".move tr").eq(1);

					let entry = selectedEntries[0];
					let $template = $(".context-info .move .template").clone();

					let ceHandle = $(`[data-id-team=${entry.idPresetTeam}]`).find(".ce .worker-label").text();

					// format date
					let tmpDate = new Date(entry.date);
					let date = `${tmpDate.getDate()}.${tmpDate.getMonth() + 1}.${tmpDate.getFullYear()}`;

					$template.removeClass("template");
					$template.find("td").first().text(entry.idProject);
					$template.find("td").eq(1).text(date)
					$template.find("td").eq(2).text(entry.position)
					$template.find("td").last().text(ceHandle);

					$appendToEl.after($template);
				}
			}

		}
		function updateContextEntriesUserStatus() {
			// console.log('update context entries user states');

			// remove old entries
			let $oldEntries = $contextInfoUserStatus.find("tr:not(.template)");
			$oldEntries.nextAll().remove();

			let $appendToEl = $contextInfoUserStatus.find("tbody");

			selectedEntries.forEach((entry, i) => {
				if (entry.toDelete)
					return;

				let $template = $contextInfoUserStatus.find(".template").clone();
				$template.removeClass("template");

				// format date
				let tmpDate = new Date(entry.startDate);
				let startDate = `${tmpDate.getDate()}.${tmpDate.getMonth() + 1}.${tmpDate.getFullYear()}`;
				tmpDate = new Date(entry.endDate);
				let endDate = `${tmpDate.getDate()}.${tmpDate.getMonth() + 1}.${tmpDate.getFullYear()}`;


				$template.find("td").first().text(startDate);
				$template.find("td").eq(1).text(entry.endDate ? endDate : "");
				$template.find("td").eq(2).text(entry.status);

				$template.find(".remove").click(function (e) {

					if (entry.toAdd !== undefined && entry.toAdd)
						selectedEntries.splice(i, 1);
					else
						entry.toDelete = true;
					applySelectedUserStatusEntriesStyle();
					updateContextEntriesUserStatus();
				});

				$appendToEl.append($template);
			});



		}

		function createCopy(date, position, idTeam) {
			return {
				date,
				position,
				idPresetTeam: idTeam
			};
		}

		async function setStartingEntry(idDayProjectTeam) {

			if (!idDayProjectTeam)
				return;

			// fade out all other preselected entries except selected one
			$(`.planner-entry:not([data-id-entry=${idDayProjectTeam}])`).removeClass("preselected").addClass("fade-out");

			// fade in empty entries
			$(".planner-entry.empty").removeClass("fade-out");

			SELECTED_ID_ENTRY = idDayProjectTeam;
			let entryInfos = await fetchEntryInfos(idDayProjectTeam);
			SELECTED_ID_PROJECT = entryInfos.project.idProject;

			projectSelected = true;
		}

		function applySelection() {

			if (CURRENT_MODE === MODE.multi_select) {

				switch (CURRENT_SELECT_MODE) {

					// copy normal/special selection
					case SELECT_MODE.normal:
					case SELECT_MODE.special:
					case SELECT_MODE.move: {
						$(".planner-entry.empty").find("i").show();
						$(".planner-entry.empty").find("span").html("");

						setStartingEntry(SELECTED_ID_ENTRY);


						// select already selected entries
						selectedEntries.forEach(entry => {
							let $currentEl = $(`[data-id-team=${entry.idPresetTeam}] [data-date='${entry.date}'] [data-position=${entry.position}]`);
							$currentEl.addClass("preselected");

							$currentEl.find("i").hide();
							$currentEl.find("span").html(entry.idProject.replace(" ", "<br>"));
							$currentEl.addClass("preselected");

						})
						break;
					}

					case SELECT_MODE.delete: {

						selectedEntries.forEach(entry => {
							$(`[data-id-entry=${entry.idDayProjectTeam}]`).removeClass('preselected').addClass("to-be-removed");
						});

						break;
					}

				}
				updateContextEntriesMultiSelect();
				openContextInfoMultiSelect();
			}
		}


		function getStatusClass(status) {
			switch (status) {
				case 'Ma':
					status = "sick";

					break;
				case 'A':
					status = "absent";
					break;
				case 'Ex':
					status = "exceptionnel";
					break;
				case 'Fo':
					status = "formation";
					break;
				case 'Nf':
					status = "unjustified";
					break;
				case 'Rec':
					status = "recuperation";
					break;

				default:
					status = "default"
					break;
			}


			return status;
		}

		async function performSelectedEntryAction() {

			// abort if no entries selected
			if (selectedEntries.length === 0)
				return;
			let result;

			if (CURRENT_MODE === MODE.multi_select) {
				switch (CURRENT_SELECT_MODE) {

					case SELECT_MODE.normal: case SELECT_MODE.special: {

						result = JSON.parse(await $.post(`${BASE_URL}planner/copyEntry`, {
							idDayProjectTeam: SELECTED_ID_ENTRY,
							selectedEntries,
							copyMode: CURRENT_SELECT_MODE
						}));
						break;
					}
					case SELECT_MODE.delete: {

						result = await deleteSelectedEntries();
						break;
					}

					case SELECT_MODE.move: {
						result = await moveToSelectedEntry();
						break;
					}

				}

			}

			else if (CURRENT_MODE === MODE.user_status) {
				result = JSON.parse(await $.post(`${BASE_URL}planner/setUserStatus`, {
					idUser: SELECTED_USER,
					entries: selectedEntries
				}));

			}

			if (result.success === 1) {
				setMode(MODE.overview);
				applyView(result.view);
				closeContextInfoMultiSelect();
				closeContextInfoUserStatus();
				closeUserStatusModal();
			}

		}
		async function deleteSelectedEntries() {

			let result = JSON.parse(await $.post(`${BASE_URL}planner/deleteSelectedEntries`, {
				selectedEntries
			}));
			return result;
		}
		async function moveToSelectedEntry() {

			let result = JSON.parse(await $.post(`${BASE_URL}planner/moveToSelectedEntry`, {
				sourceEntryId: SELECTED_ID_ENTRY,
				targetEntry: selectedEntries[0]
			}));
			return result;
		}

		function abortSelection() {
			CURRENT_SELECT_MODE = null;
			selectedEntries = [];
			closeContextInfoMultiSelect();
			closeContextInfoUserStatus();
			$(".planner-entry.empty").find("i").show();
			$(".planner-entry.empty").find("span").html("");

			setMode(MODE.overview);
		}

		async function getHandleByIdUser(idUser) {
			return await $.post(`${BASE_URL}planner/getHandleByIdUser`, {idUser});
		}

		function togglePlannerEntry() {

			let $selectedEntry = getSelectedEntry();

			// selected user from team details
			let $selectedUser = $(`[data-id-team=${SELECTED_TEAM}]`).find(`.entry[data-id-user=${SELECTED_USER}]`);


			// get the current presence status of the selected team user for the selected planner entry
			let isRemovedFromProject = $selectedEntry.hasClass("removed");
			let isActiveOnProject = $selectedEntry.hasClass("highlighted") && !isRemovedFromProject;
			let isBorrowedUser = $selectedUser.hasClass("borrowed");

			let teamIdOfEntry = $selectedEntry.closest(".team").data("id-team");

			// cases for team users
			if (!isBorrowedUser) {

				// inside same team row as selected user
				if (SELECTED_TEAM === teamIdOfEntry) {
					if (isActiveOnProject)
						removeTeamUser(SELECTED_USER, SELECTED_ID_ENTRY);
					else if (isRemovedFromProject)
						addTeamUser(SELECTED_USER, SELECTED_ID_ENTRY);
				}

				// inside another team row
				else {

					if (isActiveOnProject) {

						removeBorrowedUser(SELECTED_USER, SELECTED_ID_ENTRY);
					}
					else {

						borrowUser(SELECTED_USER, SELECTED_ID_ENTRY);
					}
				}
			}
			// borrowed user
			else {
				// inside same team row as selected user
				if (SELECTED_TEAM === teamIdOfEntry) {

					if (isActiveOnProject) {
						removeBorrowedUser(SELECTED_USER, SELECTED_ID_ENTRY);
					}
					else
						borrowUser(SELECTED_USER, SELECTED_ID_ENTRY);
				}

				// inside another team row
				else {

					// selected user is a team user
					if (isTeamUserForTeam(SELECTED_USER, teamIdOfEntry)) {

						if (isActiveOnProject) {
							removeTeamUser(SELECTED_USER, SELECTED_ID_ENTRY);
						}
						else {
							addTeamUser(SELECTED_USER, SELECTED_ID_ENTRY);
						}
					}
					else {
						if (isActiveOnProject)
							removeBorrowedUser(SELECTED_USER, SELECTED_ID_ENTRY);
						else
							borrowUser(SELECTED_USER, SELECTED_ID_ENTRY);
					}
				}
			}
		}

		async function ccHandler(e) {

			let $this = $(this);
			let idCC = $this.data("id-cc");
			let idCCs = [];
			let teamsToLoad = null;
			$this.toggleClass("active");

			if($this.hasClass('all')){
				idCCs = [];
				teamsToLoad = "all";
				$(".cc-chooser .active:not(.all)").removeClass("active");
			}
			else{
				idCCs = $.map($(".cc-chooser .active"),(el, i) => {
					return $(el).data("id-cc");
				});

				if(idCCs.length > 0)
					$(".cc-chooser .all").removeClass("active");

				if($(".cc-chooser .sans-cc").hasClass('active')){
					teamsToLoad = "sansCC";
					$(".cc-chooser .all").removeClass("active");
				}
			}

			let route = `${BASE_URL}planner/setCCs`;

			let view = await $.post(route, {
				"idCCs[]": idCCs,
				"teamsToLoad": teamsToLoad
			});

			applyView(view);

		}


		function selectNormalCopy() {
			// prevent mode switch if not in overview mode
			if (CURRENT_MODE !== MODE.overview)
				return;

			$(".team-details").addClass("fade-out");
			$(".planner-entry.empty").addClass("fade-out");
			$(".project-selector .opener").addClass("active");
			CURRENT_SELECT_MODE = SELECT_MODE.normal;
			setMode(MODE.multi_select);

		}
		function selectSpecialCopy() {
			// prevent mode switch if not in overview mode
			if (CURRENT_MODE !== MODE.overview)
				return;

			setMode(MODE.multi_select);
			CURRENT_SELECT_MODE = SELECT_MODE.special;
		}

		function selectDelete() {
			// prevent mode switch if not in overview mode
			if (CURRENT_MODE !== MODE.overview)
				return;

			// set modes
			CURRENT_SELECT_MODE = SELECT_MODE.delete;
			openContextInfoMultiSelect();
			preselectProjects();
			setMode(MODE.multi_select);

		}

		function selectConfirmMode(e = null, enable = false) {

			if (enable) {
				setMode(MODE.confirm, false, false);
				$(".confirmer").addClass("enabled");
				preselectProjects();
				$(".planner-entries").addClass("user-selected");
				$(".confirmer").addClass("enabled");
			}

			else {

				if (CURRENT_MODE === MODE.confirm) {
					$(".confirmer").removeClass("enabled");
					setMode(MODE.overview, true, true);
				}

				else {
					preselectProjects();
					$(".planner-entries").addClass("user-selected");
					$(".confirmer").addClass("enabled");
					setMode(MODE.confirm);
				}
			}
		}
		function selectMove() {
			// prevent mode switch if not in overview mode
			if (CURRENT_MODE !== MODE.overview)
				return;

			// set modes
			setMode(MODE.multi_select);
			CURRENT_SELECT_MODE = SELECT_MODE.move;
			openContextInfoMultiSelect();

		}

		function preselectProjects() {
			$(".planner-entry:not(.empty)").addClass("preselected");
		}

		function updateProjectNote(){

			let $note = $(".inspector .notes textarea");

			let note = $note.val();

			note !== "" ? getSelectedEntry().append("<i class='fas fa-sticky-note project-note'></i>") : getSelectedEntry().find(".project-note").remove();
			$.post(`${BASE_URL}planner/updateProjectNote`, {
				idDayProjectTeam: SELECTED_ID_ENTRY,
				note
			}, () => {

				VanillaToasts.create({
					text: "Updated the note",
					type: 'success',
					timeout: 3000,
					icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
				});
			});
		}



		async function setMode(mode, callNewMode = false, undoLastMode = true) {
			// console.log('set mode with: ' + mode);
			if (mode !== CURRENT_MODE && undoLastMode) {
				undoCurrentMode();
			}
			if (CURRENT_MODE === MODE.overview && mode === MODE.overview) {
				return;
			}
			CURRENT_MODE = mode;

			if (CURRENT_MODE === MODE.overview) {
				SELECTED_USER = null;
				SELECTED_ID_ENTRY = null;
				SELECTED_TEAM = null;
				projectSelected = false;
				selectedEntries = [];


				$(".planner-entries").removeClass("user-selected");
				$(".entry.selected").removeClass("selected");

				$(".status-chooser").addClass("hide");
				$(".fade-out").removeClass("fade-out");
				$(".preselected").removeClass("preselected");
				$(".headline .opener").removeClass("active");

				closeContextInfoMultiSelect();

				// startDragula();
			}
			// else stopDragula();

			if (!callNewMode) {
				return;
			}
			if (CURRENT_MODE === MODE.user_selected) {
				await selectUser(null, SELECTED_USER);
			}

			if (CURRENT_MODE === MODE.multi_select) {

				selectDelete();
			}

			if (CURRENT_MODE === MODE.user_status) {
				// console.log('user status');

				await selectUser(null, SELECTED_USER);
				selectUserStatus(null, SELECTED_USER_STATUS);

			}

			if (CURRENT_MODE === MODE.confirm) {
				selectConfirmMode(null, true);
			}
		}

		function undoCurrentMode() {
			if (CURRENT_MODE === MODE.overview)
				return;
			switch (CURRENT_MODE) {
				case MODE.user_selected:
					undoUserSelectedMode();
					break;
				case MODE.user_status:
					undoUserStatusMode();
					break;
			}
		}

		function undoUserSelectedMode() {

			let $sundayEl = $(".date-labels .sunday");
			let $todayEl = $(".date-labels .today");
			let $confirmedEls = $(".planner-entry.confirmed");

			$(`.date-labels li`).removeClass();
			$(`.date-labels li`).attr("data-states", null);
			$sundayEl.addClass("sunday");
			$todayEl.addClass("today");
			$(".team-details .selected").removeClass("selected");
			$(".planner-entry:not(.empty)").removeClass().addClass("planner-entry");
			$(".planner-entries").removeClass("user-selected");
			$confirmedEls.addClass("confirmed");
			SELECTED_USER = null;
		}

		function undoUserStatusMode() {

			let $sundayEl = $(".date-labels .sunday");
			$(`.date-labels li`).removeClass();
			$(`.date-labels li`).attr("data-states", null);
			$sundayEl.addClass("sunday");

			$(".status-chooser .opener").removeClass("active");
			$(".status-chooser .selected").removeClass("selected");
			$(".planner-entry.highlighted").removeClass("highlighted");
			$(".project-selector").fadeIn();
			SELECTED_USER_STATUS = null;
		}

		function undoProjectSelectedMode() {
			$inspector.addClass("closed");
			SELECTED_ID_ENTRY = null;
		}

		function getSelectedDate() {
			return $(`[data-id-entry='${SELECTED_ID_ENTRY}']`).parent().data("date");
		}

		function getSelectedTeam() {
			return $(`[data-id-entry='${SELECTED_ID_ENTRY}']`).closest(".team").data("id-team");
		}

		function getSelectedEntry() {
			return $(`[data-id-entry='${SELECTED_ID_ENTRY}']`);
		}
		function getSelectedUserEntry () {
			return $(`[data-id-team=${SELECTED_TEAM}] [data-id-user='${SELECTED_USER}']`);
		}

		let startPresetTeamIds;
		function setOriginalTeamPrefArray(){
			startPresetTeamIds = $(".team").map((i, el) => {
				// console.dir(el);

				return $(el).data("id-team");
			}).get();

		}
		async function setTeamPreferenceForUser(el, target, source, sibling) {
			let endPresetTeamIds = $(".team").map((i, el) => {
				// console.dir(el);

				return $(el).data("id-team");
			}).get();
			endPresetTeamIds.shift();
			let result = await $.post(`${BASE_URL}planner/setTeamPreferenceForUser`, {
				startPresetTeamIds,
				endPresetTeamIds,
			});
		}

		async function toggleDailyNoteInspector($dayNoteEl){
			if(CURRENT_MODE !== MODE.overview)
				return;
			// setup notes inspector
			if($notesInspector.hasClass("closed")){
				let date = $dayNoteEl.parent().data('date');

				// set the date as title and data attribute
				$notesInspector.find(".date-label").text($dayNoteEl.prev().text());
				$notesInspector.find(".date-label").data("date", $dayNoteEl.parent().data("date"));

				// fetch the note
				let note = JSON.parse( await $.post(`${BASE_URL}planner/fetchNoteForDate`, {date}));
				$notesInspector.find("textarea").val(note);
			}

			// cleanup
			else{
				setTimeout(() => {
					$notesInspector.find(".date-label").text("");
					$notesInspector.find(".date-label").data("date", null);
				}, 600);
			}

			$notesInspector.toggleClass("closed");
		}

		async function saveNote(){

			let day = $notesInspector.find(".date-label").data('date');
			let note = $notesInspector.find("textarea").val();

			note = note !== "" ? note : null;

			let response = JSON.parse(await $.post(`${BASE_URL}planner/setDayNote`, {
				day,
				note
			}));

			VanillaToasts.create({
				text: response.message,
				type: response.success ? 'success' : 'error',
				timeout: 3000,
				icon: `${BASE_URL}src/assets/images/bettendorf_mobile_login_logo.svg`
			});

			// update note icon/text for date label based on action to note

			if(note && response.success)
				$(`.date-labels [data-date='${day}']`).find(".day-note").addClass("enabled");
			else
				$(`.date-labels [data-date='${day}']`).find(".day-note").removeClass("enabled");
			toggleDailyNoteInspector();


		}

		async function fetchStatusDaysForUser(idUser) {
			// console.log('fetch status days');

			return JSON.parse(await $.post(`${BASE_URL}planner/getUserStatesForDateRangeAndUser`, {
				idUser
			}));
		}

		async function selectUserStatus(e = null, status = null) {



			setMode(MODE.user_status, false, false);

			// remove old selection
			$(".status-chooser .selected").removeClass();

			if (!status)
				status = $(e.target).data('status');

			let $selectedEl = $(`.status-chooser li:contains(${status})`);
			$selectedEl.addClass("selected");

			// select another status
			if (SELECTED_USER_STATUS !== status && SELECTED_USER_STATUS !== null) {
				SELECTED_USER_STATUS = status;
				return;
			}
			SELECTED_USER_STATUS = status;
			$(".user-selected").removeClass("user-selected");

			updateContextEntriesUserStatus();
			openContextInfoUserStatus();

		}

		async function openUserStatusModal() {
			setMode(MODE.user_status, false, false);
			$userStatusModal.removeClass("closed");
			closeContextInfoUserStatus();
			let entries = await fetchUserStatesForUpcomingDates(SELECTED_USER);
			populateUserStatusModal(entries);

		}
		function closeUserStatusModal() {

			$userStatusModal.addClass("closed");
			selectedEntries = [];
		}

		function userStatusHandler(clickedEntryEl, date = null) {

			if (CURRENT_MODE !== MODE.user_status)
				return;

			let $clickedEntryEl = $(clickedEntryEl);

			let dateOfColumn = date || $clickedEntryEl.parent().data("date");

			let tempStatusObj = {
				startDate: dateOfColumn,
				endDate: null,
				toAdd: true,
				status: SELECTED_USER_STATUS,
				formattedStatus: STATUS_CLASSES[SELECTED_USER_STATUS]
			};

			let found = false;
			let selectedDate = moment(dateOfColumn);

			for (let i = selectedEntries.length - 1; i >= 0; i--) {
				let currentEntry = selectedEntries[i];

				let entryIsRange = currentEntry.startDate && currentEntry.endDate;

				if (entryIsRange) {
					let entryDateRange = moment.range(currentEntry.startDate, currentEntry.endDate);

					// date is in daterange

					if (selectedDate.within(entryDateRange)) {

						if (!currentEntry.toDelete && currentEntry.status === SELECTED_USER_STATUS) {

							handleDateRangeSplit(moment(dateOfColumn), currentEntry);
							found = true;
						}
					}

				}
				// date already found
				else if (selectedEntries[i].startDate === dateOfColumn && selectedEntries[i].status === SELECTED_USER_STATUS) {

					// date is newly added
					if (selectedEntries[i].toAdd)
						selectedEntries.splice(i, 1);

					// restore existing entry
					else if (selectedEntries[i].toDelete)
						selectedEntries[i].toDelete = false;

					// mark entry to be deleted
					else
						selectedEntries[i].toDelete = true;

					found = true;
				}
			}

			// create new entry
			if (!found)
				selectedEntries.push(tempStatusObj);

			applySelectedUserStatusEntriesStyle();
			updateContextEntriesUserStatus();
		}

		async function setupWeekSwitcherDatePicker(){
			let datepickerOptions = {
				format: "dd.mm.yyyy",
				autoHide: true,
				weekStart: 1
			}
			let $input = $("[data-toggle=week-date-picker]");
			$input.datepicker(datepickerOptions);
			$input.on("pick.datepicker", async (e) =>  {

				let date = new Moment(e.date).format("YYYY/MM/DD");

				let response = await $.post(`${BASE_URL}planner/setDate`, {date});

				applyView(response);

			});

		}

		/**
		 * Returns true if the given user is a teamuser (not borrowed) for a given team
		 * @param {int} idUser
		 * @param {int} idTeam
		 */
		function isTeamUserForTeam(idUser, idTeam) {
			let $selectedUser = $(`[data-id-team=${idTeam}] .team-details [data-id-user=${idUser}]`);

			return !$selectedUser.hasClass("borrowed");
		}

		/**
		 *
		 * @param {Moment} date
		 * @param {Object} statusEntry
		 */
		function handleDateRangeSplit(date, statusEntry) {
			let entryDateRange = moment.range(statusEntry.startDate, statusEntry.endDate);

			let dateRangeStart = entryDateRange.start;
			let dateRangeEnd = entryDateRange.end;

			if (date.isAfter(dateRangeStart)) {
				selectedEntries.push({
					startDate: dateRangeStart.format("YYYY-MM-DD"),
					endDate: new Moment(date).subtract("1", "d").format("YYYY-MM-DD"),
					status: statusEntry.status,
					formattedStatus: statusEntry.formattedStatus,
					toAdd: true,
				});
			}

			if (date.isBefore(dateRangeEnd)) {
				selectedEntries.push({
					startDate: new Moment(date).add("1", "d").format("YYYY-MM-DD"),
					endDate: dateRangeEnd.format("YYYY-MM-DD"),
					status: statusEntry.status,
					formattedStatus: statusEntry.formattedStatus,
					toAdd: true,
				});
			}
			statusEntry.toDelete = true;

		}

		async function findHandle() {
			// find the handle of the selected user
			let $worker = $("[name='worker']");

			let handle = null;
			$worker.each((index, el) => {
				if (el.value) {
					handle = el.value;
				}
			});
			return handle;
		}

		async function findIdUser() {
			return $(this).closest(".borrowed-user").attr("data-id-user");
		}
		async function getIdUserByHandle(handle) {

			let result = await $.post(`${BASE_URL}planner/getIdUserByHandle`, { handle });
			result = JSON.parse(result);

			return parseInt(result);
		}

		async function setDateAndRedirectToTrip(e){
			let date = e.target.parentElement.getAttribute('data-date');

			await $.post(`${BASE_URL}trip/setDate`, {
				date
			});
			location.href = `${BASE_URL}trip`;
		}

		function setup() {

			$contextInfoMultiSelect = $(".context-info.multiselect");
			$contextInfoUserStatus = $(".context-info.user-status");
			$inspector = $(".inspector");
			$notesInspector = $(".notes-inspector");
			// startDragula();
			setMode(CURRENT_MODE, true);

			applySelection();

			$("#drag-toggler").attr("checked", drakeEnabled ? "checked" : null);
			drakeEnabled ? startDragula() : stopDragula();
			setupWeekSwitcherDatePicker();
		}

		function startDragula() {
			// make teams draggable
			let draggableElements = [].slice.call(document.getElementsByClassName("teams"));
			drake = dragula(draggableElements, {
				mirrorContainer: document.getElementsByClassName("mirror")[0]
			});

			drakeListeners();
		}
		function drakeListeners() {
			let container = [].slice.call(document.getElementsByClassName("teams"))[0];
			container.addEventListener('touchmove', event => event.preventDefault());

			drake.on('drop', setTeamPreferenceForUser)
			drake.on('drag', setOriginalTeamPrefArray);
		}

		function stopDragula() {
			if (drake)
				drake.destroy();
		}

		function toggleDragula() {
			if (drakeEnabled)
				stopDragula();
			else
				startDragula();

			drakeEnabled = !drakeEnabled;
		}

		function setupListeners() {
			// HEADER NAV
			$(".app").on("click", ".shift-forward-day", shiftDayForward);
			$(".app").on("click", ".shift-backward-day", shiftDayBackward);
			$(".app").on("click", ".shift-backward-week", shiftWeekBackward);
			$(".app").on("click", ".shift-forward-week", shiftWeekForward);
			$(".app").on("click", ".week .today", setDateToToday);
			// $(".app").on("click", ".week input", openDatePicker);
			setupWeekSwitcherDatePicker()

			$(".app").on("change", ".drag-toggler", toggleDragula);


			// CONFIRMER
			$(".app").on("click", ".confirmer", selectConfirmMode);

			// COPY / DELETE / MOVE BUTTONS
			$(".app").on("click", ".normal-copy", selectNormalCopy);
			$(".app").on("click", ".special-copy", selectSpecialCopy);
			$(".app").on("click", ".project-selector .delete", selectDelete);
			$(".app").on("click", ".project-selector .move", selectMove);

			// CC chooser
			$(".app").on("click", ".cc-chooser li", ccHandler);

			// STATUS-CHOOSER
			$(".app").on("click", ".status-chooser .item", selectUserStatus.bind(this));
			$(".app").on("click", ".status-chooser .modal", openUserStatusModal);

			// DATE LABELS
			$(".app").on("click", ".date-labels .day-note", function(){
				toggleDailyNoteInspector($(this));
			});
			$(".app").on("click", ".date-labels li", function(){
				userStatusHandler(null, this.getAttribute('data-date'));
			});

			$(".app").on("click", ".date-labels .lkw-planner", setDateAndRedirectToTrip);

			// TEAM VIEW
			$(".app").on("click", ".team-details .entry", selectUser);


			// PLANNER VIEW
			$(".app").on("click", ".add-row-button", addRowForTeam);

			$(".app").on("click", ".planner-entry", plannerEntryHandler);
			$("body").click(closeInspector);

			// INSPECTOR VIEW

			// team users
			$(".app").on("click", ".inspector .team-member .remove", function (e) {
				let idUser = $(this).closest(".team-member").attr("data-id-user");

				removeTeamUser(idUser, SELECTED_ID_ENTRY);
			});
			$(".app").on("click", ".inspector .team-member .add", function (e) {
				let idUser = $(this.closest(".team-member")).data("id-user");
				addTeamUser(idUser, SELECTED_ID_ENTRY);
			});

			// borrowed users
			$(".app").on("click", ".inspector .borrowed-user .remove", function (e) {

				findIdUser.call(this).then(idUser => removeBorrowedUser(idUser, SELECTED_ID_ENTRY));
			});

			$(".app").on("click", ".inspector .borrow-user .add", e => findHandle().then(handle => getIdUserByHandle(handle).then(idUser => borrowUser(idUser, SELECTED_ID_ENTRY))));

			$(".app").on("change", ".inspector .notes textarea", updateProjectNote);

			// DAILY NOTES INSPECTOR
			$(".app").on("click", ".notes-inspector .save", saveNote);
			$(".app").on("click", ".notes-inspector .abort", toggleDailyNoteInspector);


			// CONTEXT INFO VIEW
			$(".app").on("click", ".save", performSelectedEntryAction);
			$(".app").on("click", ".abort", abortSelection);

			// USER STATUS MODAL

			$userStatusModal.find(".abort").click(closeUserStatusModal);
			$userStatusModal.find(".add").click(addUserStatusRow);

			$(window).keyup((e) => {
				if(e.key === "Enter")
					performSelectedEntryAction();
			})
		}


	}
});
